import React from 'react'
import {Div,Row,Col,Text,Anchor, Container} from 'atomize'
//import { Link } from 'react-router-dom';
import Builar from '../public/builar-complete.svg'

const Footer = () => {
    return (
        <footer>
            <div style={{backgroundColor:'white'}}>
            <Container >
                {/*Anchor links*/}
                <Row m={{b:{xs: "2rem",sm:"2rem",lg:"4rem"}}} p={{ y: { xs: '3rem', md: '5rem' }}}>
                    <Col size={{xs:12,sm:12,md:3,lg:3}}>
                        <Div
                            bgImg={Builar}
                            bgSize="contain"
                            bgRepeat="no-repeat"
                            h="100%"
                            bgPos="left"
                        />
                    </Col>              
                    <Col size={{xs:12,sm:12,md:3,lg:3}}>
                        <Text m={{b:{xs: "0.5rem",sm:"1rem",lg:"1rem"}}}  fontFamily="code" textSize={{xs: "body",sm:"paragraph",md:"paragraph",lg:"subheader"}} textColor="blue" >
                            ¿Cómo funciona?
                        </Text>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Servicios de Builar
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Programa de impulso
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Preguntas Frecuentes
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Terminos y condiciones
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Requisitos
                        </Anchor>
                    </Col>
                    <Col size={{xs:12,sm:12,md:3,lg:3}}>
                        <Text m={{b:"1rem"}}  fontFamily="code" textSize={{xs: "body",sm:"paragraph",md:"paragraph",lg:"subheader"}} textColor="blue">
                            Programa de Impulso
                        </Text>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Preguntas Frecuentes
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Requisitos
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Inscribirme
                        </Anchor>
                    </Col>
                    <Col size={{xs:12,sm:12,md:3,lg:3}}>
                        <Text m={{b:"1rem"}}  fontFamily="code" textSize={{xs: "body",sm:"paragraph",md:"paragraph",lg:"subheader"}} textColor="blue">
                            Contacto
                        </Text>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",md:"paragraph",lg:"paragraph"}}
                        >
                            Ubicación
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",lg:"paragraph"}}
                        >
                            Instagram
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",lg:"paragraph"}}
                        >
                            Facebook
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",lg:"paragraph"}}
                        >
                            Youtube
                        </Anchor>
                        <Anchor
                            href="https://www.google.com"
                            target="_blank"
                            d="block"
                            textColor="blackText"
                            hoverTextColor="brick"
                            textSize={{xs: "caption",sm:"body",lg:"paragraph"}}
                        >
                            Envíanos un e-mail
                        </Anchor>
                    </Col>
                </Row>
                
                {/*Copyright*/}
                <Row p="1rem" m="0rem"  style={{borderTop:"0.1rem solid"}}>
                <Col size={{xs: 12,sm:12,md:11,lg:12}} p={{y:"1rem"}}>
                    <Text textSize={{xs: "paragraph",sm:"paragraph",lg:"subheader"}}>
                        © 2020-2021 Builar. Todos los derechos reservados.
                    </Text>
                </Col>
                <Col size={{xs: 4,sm:4,md:4,lg:4}}>
                    <Anchor
                        href="https://www.google.com"
                        target="_blank"
                        d="block"
                        textColor="blackText"
                        hoverTextColor="brick"
                        textSize={{xs: "caption",sm:"paragraph",lg:"subheader"}}
                    >
                        Terminos y Condiciones
                    </Anchor>
                </Col>
                <Col size={{xs: 3,sm:3,md:3,lg:3}}>
                    <Anchor
                        href="https://www.google.com"
                        target="_blank"
                        d="block"
                        textColor="blackText"
                        hoverTextColor="brick"
                        textSize={{xs: "caption",sm:"paragraph",lg:"subheader"}}
                    >
                        Privacidad
                    </Anchor>
                </Col>
                <Col size={{xs: 4,sm:4,md:4,lg:4}}>
                   <Anchor
                        href="https://www.google.com"
                        target="_blank"
                        d="block"
                        textColor="blackText"
                        hoverTextColor="brick"
                        textSize={{xs: "caption",sm:"paragraph",lg:"subheader"}}
                    >
                        Aviso Legal
                    </Anchor> 
                </Col>

            </Row>
            
            </Container>
            </div>
        </footer>
    )
}

export default Footer
