import {React} from 'react'
import {Row,Col,Text,Input,Icon, Container} from 'atomize'

const NavBarProjects = ({Back}) => {
    const config={
        textSize:{ xs: 'tiny',sm:'tiny',md:'caption',lg:"paragraph"},
        fontFamily:"code",
        cursor:"pointer",
        m:{t:"0.5rem"}
    }
    
    return (
        <nav>
            
            <Container p={{x:{xs:"1rem",md:"4rem"},y:"1rem"}}>
                
                
                    <Row p={{x:"0.1rem"}} h={{ xs: '6rem',sm:'3rem',md:'3rem',lg:"3rem"}} border="1px solid" borderColor="grayBorder" rounded="sm">
                        
                        <Col size={{xs:6,sm:6,md:3,lg:2}} d="grid" style={{placeItems:"center"}}>
                            <Text 
                            className="itemMenu"
                            {...config}
                            >Mis Proyectos</Text>
                        </Col>
                        <Col size={{xs:6,sm:6,md:3,lg:3}} d="grid" style={{placeItems:"center"}}>
                            <Text 
                            className="itemMenu"
                            {...config}
                            >Programa de Impulso</Text>
                        </Col>
                        <Col size={{xs:12,sm:12,md:6,lg:7}}>
                            <Input
                                m={{t:".2rem"}}
                                onChange={(e)=>
                                    Back(e.target.value)
                                }
                                placeholder="Buscar proyectos, impulsos, etc"
                                suffix={
                                    <Icon
                                    name="Search"
                                    size={{xs:"1rem",sm:"1rem",md:"1.5rem",lg:"1.5rem"}}
                                    cursor="pointer"
                                    pos="absolute"
                                    top="50%"
                                    right="1rem"
                                    transform="translateY(-50%)"
                                    />
                                }
                                />
                        </Col>

                    </Row>
               
        
            </Container>

        </nav>
    )
}

export default NavBarProjects
