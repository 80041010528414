import React from 'react'
import {Div, Text,Row,Col} from 'atomize'
import ProgressBar from './ProgressBar'
import Tags from './Tags'

const CardUs = ({icon,title,text}) => {
  return (
    <Col size={{ xs: 12,sm:12,lg:4}} m={{b:{xs: "1.5rem",sm:"1.5rem",lg:"0rem"}}} p={{ x: { xs: '0.5rem', md: '0.8rem' }, y: { xs: '1rem', md: '0.8rem' }}} >
      <Row>
        <Col size={3} d="grid" d="flex" align="flex-start" justify="center" className="mt-2" >
            <span className="material-icons" style={{fontSize:"3.5rem",color:"rgb(238, 83, 64)",textAlign:"center"}}>
                {icon}
            </span>
        </Col>
        <Col size={9} >
            <Text fontFamily="code" textAlign="left" textSize="heading" textColor="brick">
                {title}
            </Text>
            <Text fontFamily="secondary" textAlign="left" textSize="subheader" textColor="gray">
                {text}
            </Text>
        </Col>
      </Row>
    </Col>

  )
}

export default CardUs
