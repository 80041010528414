import React from 'react'
import {Row,Col,Text,Container} from 'atomize'
import { Link } from 'react-router-dom';

const NavBarUser = ({id,selected}) => {
    const config={
        textSize:{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"},
        fontFamily:"code",
        cursor:"pointer",
        textColor:"blackText"
    }
    return (
        <nav>
            <Container
            borderColor="grayBorder"
            className="border-bottom px-0 mx-0"
            >
            <Row p={{x:{xs: '0.8rem',md:"2rem"}, t:{xs: '0.3rem',md:".5rem"}}}  h={{ xs: '2rem',sm:'3rem',md:'3rem',lg:"3rem"}}>
                            
                <Col d="grid" style={{placeItems:"center"}}>
                    <Link className="n-link" to={`/User/${id}`}>
                        <Text 
                            className={selected==1?"itemMenuSelected":"itemMenu"}
                            {...config}
                        >
                            Resumen
                        </Text>
                    </Link>
                </Col>
                <Col d="grid" style={{placeItems:"center"}}>
                <Link className="n-link" to={`/User/${id}/projects`}>
                <Text className={selected==2?"itemMenuSelected":"itemMenu"}
                {...config}
                >Proyectos</Text>
                </Link></Col>
                <Col d="grid" style={{placeItems:"center"}}>
                    <Link className="n-link" to={`/User/${id}/wallet`}>
                       <Text 
                {...config} className={selected==3?"itemMenuSelected":"itemMenu"}
                >Billetera</Text>
                 </Link></Col>
                <Col d="grid" style={{placeItems:"center"}}>
                    <Text 
                {...config} className={selected==4?"itemMenuSelected":"itemMenu"}
                
                >Ajustes</Text></Col>
                <Col></Col>
                        
            </Row>


            </Container>
        </nav>
        
    )
}

export default NavBarUser
