import {React,useState,useEffect} from 'react'
import { Div, Text } from "atomize";
import { Link } from 'react-router-dom';
import builar from '../public/SVG/builar-horiz.svg'
import { useMediaQuery } from 'react-responsive';

const Navbar = () => {
    const [show,setShow]=useState(false)
    const menu = useMediaQuery({ query: `(max-width: 1060px)` });
    const menuList = (
    <Div p={{ x: "1rem" }}>
        <Text cursor="pointer"textColor="gray" textSize="subheader"fontFamily="code"hoverTextColor="brick">¿Cómo Funciona?</Text>
        <Text cursor="pointer"textColor="gray" textSize="subheader"fontFamily="code"hoverTextColor="brick">Programa de Impulso</Text>
        <Link to="/iniciar"><Text textColor="gray" textSize="subheader"fontFamily="code"hoverTextColor="brick">Inicio/Registro</Text></Link>
        <Link to="/startProject/1"><Text textColor="gray" textSize="subheader"fontFamily="code"hoverTextColor="brick">Iniciar Proyecto</Text></Link>
    </Div>
    );

    useEffect(()=>{
        setShow(false)
    },[menu])

    return (
        <nav class="navbar navbar-marketing navbar-expand-lg bg-white navbar-light">
                        <div class="container" style={{paddingLeft:"1.5rem",paddingRight:"1.5rem"}}>
                            <Link className="nav-link" to="/"><a class="navbar-marketing navbar-brand svg text-primary m-0 p-0"><img class="svg" src={builar} alt="builar-logo" /></a></Link><button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ml-auto mr-lg-5">
                                    <li class="nav-item"><Link className="nav-link" to="/">¿Cómo funciona?</Link></li>
                                    <li class="nav-item"><Link className="nav-link" to="/">Programa de impulso</Link></li>
                                    <li class="nav-item"><Link className="nav-link" to="/iniciar">Iniciar/Registrarse</Link></li>
                                </ul>
                                <Link className="nav-link" to="/startProject/1"><a class="btn font-weight-500 ml-lg-4 btn-primary" href="/startProject/1">Iniciar proyecto<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right ml-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></a></Link>
                            </div>
                        </div>
                    </nav>
        
    )
}

export default Navbar
