import React from 'react'
import Navbar from "../Molecules/Navbar"
import { Container, Row, Col,Text,Div } from "atomize";
import BotonPrimario from '../Atoms/BotonPrimario'
import buil from '../public/townhouse.png'
import empresas from '../public/bulIcon3.png'
import industrias from '../public/bulIcon2.png'
import impulso from '../public/bulIcon1.png'
import fondo from '../public/impulso-bg.svg'
import Card from '../Atoms/Card'
import Footer from '../Molecules/Footer'
import ReactPlayer from "react-player";
import { Link } from 'react-router-dom';
import CardUs from '../Atoms/CardUs';
import { useMediaQuery } from 'react-responsive';


//Variables de prueba
const descripcion="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently"

const Principal = () => {
    return (
        <>
        <Navbar/>

        {/*Sección principal o header*/}
        <Div className="header"  bg="bg" p={{y: { xs: '1rem', md: '2rem' }}}>
            <Container >
                <Row>
                    <Col size={{ xs: 12,sm:12,md:6,lg:5}} d="flex" align="left" justify="center" flexDir="column">
                        
                        <Text m={{ b: '2rem'}} fontFamily="code" textAlign="left" textSize={{ xs: 'heading',sm:'display1',md:'display3'}} textColor="blue">
                            Construye tu futuro hoy.
                        </Text>
                        <Text m={{ b: '2rem'}} fontFamily="secondary" textAlign="left" textSize={{ xs: 'subheader',sm:'subheader',md:'title'}} textColor="gray">
                            Únete a nuestro programa de impulso y opta por una planificación de tu hogar financiada, eficiente y en el menor tiempo posible.
                        </Text>
                        <Link to="/iniciar"><BotonPrimario texto='Explorar' color='brick' font="code" icon="RightArrow"/></Link>
        
                    </Col>
                    <Col size={{ xs: 12,sm:12,md:6,lg:7}} d={{xs:"none",md:"block"}} className="h-100">

                        <Div bgImg={buil} bgSize="cover" h="600px" bgRepeat="no-repeat" />

                    </Col>
                </Row>
            </Container>
        </Div>

        {/*Servicios De Builar*/}
        <Div className="services" bg="#F1F1F1" p={{y: { xs: '1rem', md: '3rem' }}}>
            <Container > 

                <Text m={{y: { xs: '1rem', md: '1rem' }}} fontFamily="code" textAlign="left" textSize={{ xs: 'heading',sm:'display1',md:'display2'}} textColor="blue">
                    Servicios de builar
                </Text>

                    
                <Row p={{y: { xs: '1rem', md: '1rem' }}}>

                    {/*Programa de impulso*/}
                    <Col size={{ xs: 12,sm:12,md:12,lg:2}} 
                         textAlign="center" justify="center"
                         style={{marginRight:useMediaQuery({ query: `(min-width: 1060px)`})?"1rem":"0rem"}} 
                         h={{ xs: '12rem',sm:'15rem',md:'20rem',lg:"22rem"}} 
                         p={{ xs: '0rem', md: '1rem'}}
                         border=" 1px solid" 
                         borderColor="grayBorder"
                         bg="white">

                                <Div
                                    bgImg={impulso}
                                    bgPos="center"
                                    bgSize="contain"
                                    bgRepeat="no-repeat"
                                    h="50%"
                                    m={{t:{lg:"20%",md:"6%",sm:"6%",xs:"6%"},b:{lg:"20%",md:"4%",sm:"4%",xs:"4%"}}}
                                />
                                <Text textAlign="center" fontFamily="code" textSize={{ xs: 'caption',sm:'paragraph',md:'subheader'}}>Programa <br/>de impulso</Text>
                    </Col>
                     {/*Empresas*/}
                    <Col size={{ xs: 12,sm:12,md:12,lg:2}} 
                         textAlign="center" justify="center"
                         style={{marginRight:useMediaQuery({ query: `(min-width: 1060px)`})?"1rem":"0rem"}} 
                         h={{ xs: '12rem',sm:'15rem',md:'20rem',lg:"22rem"}} 
                         p={{ xs: '0rem', md: '1rem'}}
                         border=" 1px solid" 
                         borderColor="grayBorder"
                         bg="white">

                                <Div
                                    bgImg={empresas}
                                    bgPos="center"
                                    bgSize="contain"
                                    bgRepeat="no-repeat"
                                    h="50%"
                                    m={{t:{lg:"20%",md:"6%",sm:"6%",xs:"6%"},b:{lg:"20%",md:"4%",sm:"4%",xs:"4%"}}}
                                />
                                <Text textAlign="center" fontFamily="code" textSize={{ xs: 'caption',sm:'paragraph',md:'subheader'}}>builar<br/>para empresas</Text>
                    </Col>
                     {/*Industrias*/}
                    <Col size={{ xs: 12,sm:12,md:12,lg:2}} 
                         textAlign="center" justify="center"
                         style={{marginRight:useMediaQuery({ query: `(min-width: 1060px)`})?"1rem":"0rem"}} 
                         h={{ xs: '12rem',sm:'15rem',md:'20rem',lg:"22rem"}} 
                         p={{ xs: '0rem', md: '1rem'}}
                         border=" 1px solid" 
                         borderColor="grayBorder"
                         bg="white">

                                <Div
                                    bgImg={industrias}
                                    bgPos="center"
                                    bgSize="contain"
                                    bgRepeat="no-repeat"
                                    h="50%"
                                    m={{t:{lg:"20%",md:"6%",sm:"6%",xs:"6%"},b:{lg:"20%",md:"4%",sm:"4%",xs:"4%"}}}
                                />
                                <Text textAlign="center" fontFamily="code" textSize={{ xs: 'caption',sm:'paragraph',md:'subheader'}}>builar<br/>para industrias</Text>
                    </Col>
                   
                     {/*Texto descriptivo de cada uno*/}
                    <Col size={{ xs: 12,sm:12,md:12,lg:5}} d={{xs:"block",md:"flex"}} align="center">
                        <Div flexDir="col" >   
                        <Text m={{ b: { xs: '1rem',sm:'1.5rem',md:'2rem'}}} fontFamily="code" textAlign="left" textSize={{ xs: 'subheader',sm:'title',md:'title'}} textColor="blackText">
                            En builar encontrarás todo tipo de cobertura de construcción:
                        </Text>
                        <Text m={{ b: { xs: '1rem',sm:'1.5rem',md:'2rem'}}} fontFamily="primary" textAlign="left" textSize={{ xs: 'subheader',sm:'subheader',md:'subheader'}} textColor="blueText">
                            Planificación estructurada en etapas, materiales necesarios para el desarrollo, y planes de financiamiento especializados de acuerdo al tipo de propiedad.
                        </Text>
                        <Link to="/iniciar" className="n-link"><BotonPrimario texto='Construir con Builar' color='brick' font="code" tamaño="l" /></Link>

                        </Div>
                    </Col>

                </Row>
                        
            </Container>
        </Div>

        {/*Proyectos Destacados*/}
        <Div className="Projects" p={{y: { xs: '1rem', md: '3rem' }}}>
            <Container>

                <Text m={{b:'1rem' }} fontFamily="code" m={{y: { xs: '1rem', md: '1rem' }}} textAlign="left" textSize={{ xs: 'heading',sm:'display1',md:'display2'}} textColor="blue">
                    Proyectos Destacados
                </Text>

                <Div h={{ xs: 'auto',sm:'auto',md:'auto',lg:"auto"}} >
                    <Row>
                        {[1, 2, 3, 4, 5, 6].map(index => (
                            <Col size={{ xs: 12,sm:4,lg:4}} key={index} style={{marginTop:'1rem'}} >
                                <Card categoria="Urbana" titulo="C.C Palms Plaza" descripcion={descripcion} completed="7" total="14" SegundoNivel="Puerto Ordaz" PrimerNivel="Estado Bolivar" fechaInicio="12/05/2021"></Card>
                            </Col>
                        ))}

                    </Row>
                </Div>
                
            </Container>
        </Div>

        {/*Lideres en construcción*/}
        <Div className="forms" bg="white" p={{y: { xs: '1rem', md: '3rem' }}}>
            <Container>
                <Text style={{marginBottom:'1rem'}} p={{y: { xs: '1rem', md: '1rem' }}} fontFamily="code" textAlign="center" textSize={{ xs: 'heading',sm:'display1',md:'display2'}} textColor="blue">
                    Somos líderes en construcción
                </Text>   

                <Div>
                    <Row>
                        <CardUs 
                                icon="brush"
                                title="Escoge tu diseño" 
                                text="Tenemos planos estructurados y
                                    optimizados para construir de
                                    inmediato." 
                            />

                        <CardUs 
                                icon="account_balance_wallet"
                                title="Formas de pago" 
                                text="Presupuesto inteligente. Podrás construir en el menor tiempo y con bajo impacto económico." 
                            />
                        
                        <CardUs 
                                icon="build"
                                title="Construye a tu ritmo" 
                                text="Con nuestra plataforma y asesoría podrás concretar etapas en tiempos flexibles." 
                            />

                        <CardUs 
                                icon="group"
                                title="Personal capacitado" 
                                text="Puedes optar por construir por tu cuenta o con nuestros profesionales en el área." 
                            />
                        
                        <CardUs 
                                icon="thumb_up"
                                title="Te acompañamos" 
                                text="Siempre contarás con la asesoría y compañía de builar en todas las etapas de tu proyecto." 
                            />
                        
                        <CardUs 
                                icon="eject"
                                title="Programa de impulso" 
                                text="Nuestro programa es ideal para viviendas de interés social o emprendimientos. ¡Inscríbete!" 
                            />
                            
                    </Row>
                </Div>

                <Row m={{xs: "1.5rem",sm:"2rem",lg:"4rem"}}>
                    <Col size={12}d="grid" style={{placeItems:"center"}}>
                        
                        <Link to="/iniciar" className="n-link"><BotonPrimario color="brick" texto="Empezar un proyecto" tamaño="xl"font="code"/></Link>
                        
                    </Col>
                </Row>
            </Container>
        </Div>
                    
        {/*Contruye tu futuro*/}
        <Div bg="brick" style={{backgroundImage:`url(${fondo})`}}>
            <Container p={{y: { xs: '2rem', md: '3rem' }}} >

                <Row m={{b:"2rem"}} p={{xs: "1.5rem",sm:"2rem",lg:"4rem"}} h="100%">
                    
                        
                    <Col size={{xs: 12,sm:12,lg:7}} >

                        <ReactPlayer
                        width="100"
                        url="https://www.youtube.com/watch?v=o7Dl44YlPUI"
                        />

                    </Col>
                    <Col size={{xs: 12,sm:12,lg:5}} p={{xs: "0.7rem",sm:"1rem",lg:"2rem"}}>
                        
                        <Text m={{ b: {xs: "0.4rem",sm:"1rem",lg:"2rem"}}} fontFamily="code" textAlign="left" textSize={{ xs: 'title',sm:'display1',md:'display2'}} textColor="blue">
                            Tienes derecho a una vivienda digna.
                        </Text>
                        <Text m={{ b: {xs: "0.7rem",sm:"1rem",lg:"2rem"}}} fontFamily="primary" textAlign="left" textSize={{ xs: 'body',sm:'paragraph',md:'subheader'}} textColor="white">
                            Únete a nuestro programa de impulso y opta por una planificación de tu hogar financiada, eficiente y en el menor tiempo posible.
                        </Text>
                        <Link className="n-link" to="/registro"><BotonPrimario texto='Unirme' btnColor={useMediaQuery({ query: `(min-width: 1060px)`})? "btn-blue w-50":"btn-blue w-100" } color='blue' font="primary"/></Link>
                    </Col>
            
                </Row>
   
            </Container>
        </Div>    
        <Footer/>
        </>
    )
}

export default Principal
