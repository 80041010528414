import {React,useState,useEffect} from 'react'
import {Container,Row,Col,Text,Div,Tag,Icon} from 'atomize'
import NavBarLogo from '../Molecules/NavBarLogo'
import InputAll from '../Atoms/InputAll'
import DropdownProjectAtom from '../Atoms/DropdownProjectAtom'
import BotonPrimario from '../Atoms/BotonPrimario'
import { useHistory } from "react-router-dom";
import BotonSecundario from '../Atoms/BtnSecondary'
import { useMediaQuery } from 'react-responsive';


const Registrarse2 = () => {
    let history = useHistory();
    const [click,setClick]=useState(false)
    const [lista,setLista]=useState([])//{nombre,apellidos,pariente,edad,sexo,nivelEducativo,correo}
    const [inputs,setInputs]=useState({inputs:[],pariente:[],nivelEducativo:[],drops:[],opciones:[]})
    const [error,setError]=useState("")

    const [idPais,setIdPais]=useState(1)//{nombre,apellidos,pariente,edad,sexo,nivelEducativo,correo}


    const getMenu=(idDrop,idPais)=>{
        console.log(idDrop,idPais)
        if(idDrop==="NO"){
            return []
        }

        if(idPais){
            let men=inputs.opciones.filter(({caracteristicaId})=>caracteristicaId==idDrop)
            console.log(men)
            men=men.filter(({dominioId})=>dominioId==idPais)
            console.log(men)
            return men
        }

        let menu=inputs.opciones.filter(({caracteristicaId})=>caracteristicaId===idDrop)

        return menu;
    }

    const callBack=(value,name)=>{
        let aux=inputs.inputs
        let control=[name,value]
        if(aux.find(({0:name})=>name===control[0])){
            for (let index = 0; index < aux.length; index++) {
                const element = aux[index];

                if(element[0]===name){
                    (aux[index])[1]=value
                }else{
                    continue
                }
                
            }
        }else{
            aux.push([name,value])
            
        }
        setInputs({...inputs,inputs:aux})

        setClick(false)
        if(inputs.inputs.length === 9){
            console.log(inputs.inputs)
            let CI=inputs.inputs.find(({0:name})=>name==="Documento Identidad")
            CI=CI[1]
            setError("")
            if(lista.find((pariente)=>pariente.documentoIdentidad===CI)){
                setError("Pariente ya agregado")
            }else{
                let obj={nombre:inputs.inputs.find(({0:name})=>name==="Nombres")[1],
                apellidos:inputs.inputs.find(({0:name})=>name==="Apellidos")[1],
                pariente:inputs.inputs.find(({0:name})=>name==="Tipo de Pariente")[1],
                edad:inputs.inputs.find(({0:name})=>name==="Edad")[1],
                sexo:inputs.inputs.find(({0:name})=>name==="Sexo")[1],
                nivelEducativo:inputs.inputs.find(({0:name})=>name==="Nivel Educativo")[1],
                correo:inputs.inputs.find(({0:name})=>name==="E-mail")[1],
                prefijo:inputs.inputs.find(({0:name})=>name==="D.I")[1],
                documentoIdentidad:inputs.inputs.find(({0:name})=>name==="Documento Identidad")[1]}

                let aux=lista
                aux.push(obj)
                setLista(lista)
            }
            setInputs({...inputs,inputs:[]})
            
        }else{
            setError("Complete todos los campos")
        }
    }

    useEffect(()=>{
        let drops=[
            {id:"2",nombre:"D.I",tipo:"CHAR",grupoId:"1"},
            {id:"3",nombre:"Sexo",tipo:"CHAR",grupoId:"1"}
        ]
        let opciones=[
        {id:"3",valor:"CC",caracteristicaId:"2",dominioId:"2",formato:`/^\d{5,15}$/`},
        {id:"4",valor:"CE",caracteristicaId:"2",dominioId:"2",formato:`/^\d{1,9}$/`},
        {id:"5",valor:"NIT",caracteristicaId:"2",dominioId:"2",formato:`/(^[0-9]+-{1}[0-9]{1})/`},
        {id:"6",valor:"PA",caracteristicaId:"2",dominioId:"2",formato:`/^(\d{5,15})|([A-Z]{2}[0-9]{6,10})$/`},
        {id:"7",valor:"E",caracteristicaId:"2",dominioId:"1",formato:`/^[0-9]{5,15}$/`},
        {id:"8",valor:"J",caracteristicaId:"2",dominioId:"1",formato:`/^([0-9]{9})-([0-9]{1}$)/`},
        {id:"9",valor:"P",caracteristicaId:"2",dominioId:"1",formato:`/^[0-9]{8,15}$/`},
        {id:"10",valor:"V",caracteristicaId:"2",dominioId:"1",formato:`/^[0-9]{5,10}$/`},
            {id:"11",valor:"M",caracteristicaId:"3",dominioId:"1",formato:""},
            {id:"12",valor:"F",caracteristicaId:"3",dominioId:"2",formato:""}
        ]
        let pariente=[{valor:"Madre",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Padre",caracteristicaId:"1",dominioId:"1",formato:""},{valor:"Hijo",caracteristicaId:"1",dominioId:"1",formato:""},{valor:"Conyugue",caracteristicaId:"1",dominioId:"1",formato:""},{valor:"Hermano",caracteristicaId:"1",dominioId:"1",formato:""}]
        let nivelEducativo=[{valor:"Básico",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Educación media",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Universitario",caracteristicaId:"0",dominioId:"0",formato:""}]
        setInputs(data=> {return {...data,drops,opciones,nivelEducativo,pariente}})
    },[])
    return (
         <>
        <NavBarLogo/>
        <Container>
            <div class="card pricing h-100 py-4 px-0">
                <nav>
                    <Row h={{ xs: '3rem',sm:'3rem',md:'3rem',lg:"3rem"}} 
                        borderColor="grayBorder" 
                        className="border-bottom px-0 mx-0">
                        
                        <Col size={12} d="grid" style={{placeItems:"center"}}>
                            <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} 
                                    fontFamily="primary">
                                        Paso 2 de 3: Ingresa tus datos familiares
                            </Text>
                        </Col>
                        <Col size={12} className="pb-0 mb-0 px-0" d="flex" align="flex-end">
                            <Div w="100%" h=".3rem" className="pb-0 mb-0" >
                            <Div w="66%" h="100%" bg="blue"></Div>
                        </Div>
                        </Col>
                    
                    </Row>
                </nav>
                <Div className="card-body"
                m={{ x: { xs: '1rem', md: '4rem' }, y: { xs: '1rem', md: '1rem' }}}>
                    <Row m={{t:"2rem",b:"1rem"}}>
                        <Col size={{ xs: 12,sm:12,md:5,lg:5}} className="parents px-2">
                            <Div>
                                <Text textSize={{ xs: 'paragraph',sm:'paragraph',md:'subheader',lg:"subheader"}} >Parientes agregados:</Text>
                                <Row>
                                    {lista.map((familiar,index)=>{
                                        return (
                                            <Col key={index} size={12}>
                                                
                                                <div class="w-100 justify-content-center">
                                                    <Tag
                                                    bg={`redBG`}
                                                    border="1px solid"
                                                    borderColor={`brick`}
                                                    textColor={`brick`}
                                                    textSize={{ xs: 'tiny',sm:'caption',md:'body'}}
                                                    rounded="circle"
                                                    fontFamily="primary"
                                                    w="100%"
                                                    justify="left"
                                                    >
                                                        {familiar.nombre}.{familiar.apellidos[0]} - {familiar.pariente}
                                                        <Icon   name="Cross" 
                                                                size="1rem"
                                                                className="arrow-toggle"
                                                                cursor="pointer"
                                                                onClick={()=>{
                                                                    let aux=lista
                                                                    aux=aux.filter((parientes)=>parientes.documentoIdentidad!==familiar.documentoIdentidad)
                                                                    setLista(aux)
                                                                }}
                                                                color={`brick`}
                                                        />
                                                    </Tag>
                                                        
                                            </div>  
                                        </Col>
                                        )
                                    })}
                                </Row>
                            </Div>
                                
                        </Col>
                        <Col size={{ xs: 12,sm:12,md:7,lg:7}} className="parents-data">
                            <Row>

                            <Col size={{ xs: 12,sm:12,md:12,lg:12}} 
                                 p={{ x: { xs: '0rem', md: '0rem' }, y: { xs: '1rem', md: '1rem' }}}>
                                <DropdownProjectAtom  size={{ xs: "w-100", md: "w-50" }} name="Tipo de Pariente" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Tipo de Pariente") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Tipo de Pariente")[1]} menu={inputs.pariente}/>
                            </Col>

                            <Col size={12} 
                                 d={{xs:"block",sm:"block",md:"flex",lg:"flex"}} 
                                 justify="flex-start"
                                 p={{ x: { xs: '0rem', md: '0rem' }, y: { xs: '1rem', md: '1rem' }}}>
                                <InputAll size={useMediaQuery({ query: `(min-width: 1060px)`})? "w-50":"w-100" } text="Nombres" callBack={callBack} trigger={click}type="text"place=""prev={inputs.inputs.find(({0:nombre})=>nombre==="Nombres") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Nombres")[1]}/>
                                <InputAll size={useMediaQuery({ query: `(min-width: 1060px)`})? "w-50":"w-100" } text="Apellidos" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Apellidos") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Apellidos")[1]}/>
                            </Col>
                            
                           
                                 
                                
                                
                                <Col size={6} >
                                    <DropdownProjectAtom size="my-0 mt-3 ml-2" name="Sexo" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Sexo") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Sexo")[1]}
                                        menu={getMenu(inputs.drops.find((drop)=>drop.nombre==="Sexo") ? inputs.drops.find((drop)=>drop.nombre==="Sexo").id:[])}/>
                                </Col>
                                <Col size={6} >
                                    <InputAll text="Edad" callBack={callBack} trigger={click} type="number" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Edad") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Edad")[1]}/>
                                </Col>
                                
                            
                            <Col size={{ xs: 12,sm:12,md:12,lg:12}}
                                 p={{ x: { xs: '0rem', md: '0rem' }, y: { xs: '1rem', md: '1rem' }}}>
                                <DropdownProjectAtom  name="Nivel Educativo" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Nivel Educativo") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Nivel Educativo")[1]} menu={inputs.nivelEducativo}/>
                            </Col>

                            <Col size={{ xs: 12,sm:12,md:12,lg:12}}
                                 d={{xs:"block",sm:"block",md:"flex",lg:"flex"}} 
                                 justify="flex-start" align="center"
                                 p={{ x: { xs: '0rem', md: '0rem' }, y: { xs: '1rem', md: '1rem' }}}>
                                        <DropdownProjectAtom size="my-0 mt-3 ml-2" place="D.I"name="D.I" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="D.I") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="D.I")[1]} menu={getMenu((inputs.drops.find((drop)=>drop.nombre==="D.I") ? inputs.drops.find((drop)=>drop.nombre==="D.I").id:"NO"),idPais)}/>
                                
                                    <InputAll  size="w-100" text="Documento Identidad" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Documento Identidad") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Documento Identidad")[1]}/>
                            
                                </Col>     
                            <Col size={12} p={{ x: { xs: '0rem', md: '0rem' }, y: { xs: '1.5rem', md: '1rem' }}}>
                                <InputAll text="E-mail" callBack={callBack} trigger={click} type="email" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="E-mail") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="E-mail")[1]}/>
                            </Col>

                            <Row>
                                <Col size={12}><Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} textColor="red" fontFamily="code">{error}</Text></Col>
                                <Col size={12} p={{y:"1rem"}}d="grid" style={{placeItems:"start"}} onClick={()=>{
                                    setClick(true)
                                }}><BotonSecundario colorClass="btn-white" texto="Agregar" tamaño="w-100" font="font-weight-500"></BotonSecundario></Col>
                            </Row>
                            </Row>
                        </Col>
                    </Row>

                    <Row p={{ x: { xs: '0rem', md: '0rem' }, y: { xs: '1rem', md: '1rem' }}}>
                        <Col size={12} p={{y:"1rem"}} onClick={()=>{
                            history.push("registrado/1")
                        }}><BotonPrimario color="brick" texto="Continuar" btnColor={useMediaQuery({ query: `(min-width: 1060px)`})? "btn-primary w-25":"btn-primary w-100" } /></Col>
                    </Row>
                </Div>
            </div>
        </Container>
        </>
    )
}

export default Registrarse2
