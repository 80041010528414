import {React,useState,useEffect} from 'react'
import {Container,Div,Text,Tag,Icon} from 'atomize'
import NavbarLogo from '../Molecules/NavBarLogo'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import BasicInput from '../Atoms/InputMerc'
import BotonSecundario from '../Atoms/BtnSecondary'
import GoogleButton from 'react-google-button'


const InicioSesion = () => {
    const [click,setClick]=useState(false)
    const [page,setPage]=useState("Correo")
    const [valores,setValores]=useState([])
    const [error,setError]=useState("")
    let history = useHistory();
    
    useEffect(() => {
        setClick(false)
        setError("")
        if(valores.length===0){
            setPage("Correo")
        }else if(valores.length===1){
            setPage("Password")
        }else if(valores.length===2){
            //aqui se hace el axios para comprobar la clave
            console.log("comprobando")
            history.push(`/User/${1}`);
        }
    },[valores,history])

    const callBack=(valor,err)=>{
        console.log(err,valor)
        if(err && err==="Por favor llene el campo"){
            setError(err)
            setClick(false)
            return
        }
        
        if(err && err==="Formato de correo incorrecto"){
            setError(err)
            setClick(false)
            return
        }     
        if(err && err==="Muy corta"){
            setError("Contraseña debe ser al menos de 8 caractéres")
            setClick(false)
            return
        }
        if(!valor){
            
            setClick(false)
            return
        }
        /*Este es el que importa*/
        setValores((prevState) => {
            return [...prevState,valor];
        })

    }
    
    const handleClick=()=>{
        setClick(true)
    }
           
    return (
        <>
            <NavbarLogo/>           
            <Container bg="bg">
                {page==="Correo" 
                ? 
                (
                
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-6  aos-init aos-animate">
                        <div className="card pricing h-100 py-4 px-5">
                            <div className="card-body p-4 text-center">
                                <div className="text-center">
                                    <Text textSize={{ xs: 'subheader',md:'heading' }} 
                                        textColor = "black"
                                        fontFamily= "code">
                                        Ingresa con tu correo electrónico.
                                    </Text>
                                </div>
                                
                                <BasicInput  place=" " text="Correo electrónico" animated=" " trigger={click} callBack={callBack} type="email"></BasicInput>
                                
                                <Div m={{b:".5rem"}} onClick={handleClick}><BotonSecundario colorClass="btn-primary" texto="Continuar" tamaño="w-100" font="font-weight-500"></BotonSecundario></Div>
                                
                                <Link to="/registro" className="n-link"><BotonSecundario colorClass="btn-secondary" texto="Crear una cuenta" tamaño="w-100" font="font-weight-500" ></BotonSecundario></Link>
                                <hr className="my-0 mb-3"></hr>
                                <Text textSize={{ xs: 'paragraph',md:'subheader' }} 
                                        textColor = "black"
                                        fontFamily= "code"
                                        className="mb-3">
                                        También puedes iniciar/registrarte con:
                                    </Text>
                                <GoogleButton className="w-100" type="light" label="Ingresar con google"></GoogleButton>
                            </div>
                        </div>
                    </div>
                </div>

                )
                :
                (

                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-6  aos-init aos-animate">
                        <div className="card pricing h-100 py-4 px-5 ">
                            <div className="card-title text-center pt-md-4 mb-1">
                                <Text textSize={{ xs: 'subheader',md:'heading' }} 
                                    textColor = "black"
                                    fontFamily= "code">
                                    Ingresa tu contraseña.
                                </Text>
                            </div>
                            
                            <div className="card-body px-4 text-center align-items-center">
                               
                                <div className="w-100 justify-content-center">
                                    <Tag
                                    bg={`redBG`}
                                    border="1px solid"
                                    borderColor={`brick`}
                                    textColor={`brick`}
                                    textSize={{ xs: 'tiny',sm:'caption',md:'body'}}
                                    rounded="circle"
                                    fontFamily="code"
                                    justify="left"
                                    
                                    suffix={
                                    <Icon
                                        name="Cross"
                                        size={{ xs: '1rem',sm:'1.5rem',md:'1.5rem'}}
                                        m={{ l: "1rem" }}
                                        cursor="pointer"
                                        onClick={()=>setValores([])}
                                        color={`brick`}
                                        
                                    />
                                    }
                                    prefix={
                                    <Icon 
                                        name="UserCircle" 
                                        size="1.5rem" 
                                        color={`brick`}
                                        m={{ r: "0.5rem" }} 
                                    />
                                    }
                                    >
                                        {valores[0]}
                                    </Tag>
                                                
                                </div>    
                                
                                <BasicInput  place="" 
                                             text="Contraseña" 
                                             animated=" " 
                                             trigger={click} 
                                             callBack={callBack} 
                                             type="password"></BasicInput>

                                <Div m={{b:".5rem"}} onClick={handleClick}>
                                    <BotonSecundario colorClass="btn-primary" texto="Iniciar sesión" tamaño="w-100" font="font-weight-500"></BotonSecundario>
                                </Div>
                                
                                <BotonSecundario colorClass="btn-secondary" texto="Recuperar contraseña" tamaño="w-100" font="font-weight-500" ></BotonSecundario>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>

                )}
            </Container>
        </>
    )
}

export default InicioSesion
