import {React,useState} from 'react'
import {Row,Col,Div,Text} from 'atomize'
import BotonPrimario from '../Atoms/BotonPrimario'
import Card from '../Atoms/Card'
import { Link } from 'react-router-dom';

const ShowProjects = ({data}) => {
    const [page,setPage]=useState(1)
    const totalPages= data.length===0 ? 1:Math.ceil(data.length/6)
    const firstRow=data.slice((page-1)*6,((page-1)*6)+3)
    const secondRow=data.slice(((page-1)*6)+3,(((page-1)*6)+3)+3)
    const handleClick=(oper)=>{
        console.log("hola")
        if(oper==="+"){
            setPage(page===totalPages ? 1:page+1)
            console.log(page)
        }else{
            setPage(page===1 ? totalPages:page-1)
        }
    }
    return (
        <>
        <Div h={{ xs: 'auto',sm:'auto',md:'auto',lg:"auto"}} >
                <Row>
                    {firstRow.map(proyecto => (
                        <Col size={{xs:12,sm:12,md:12,lg:4}} key={proyecto.id}>
                           <Link className="n-link" to="/Project-details/1"> <Card projects={true} categoria={proyecto.categoria} titulo={proyecto.nombre} descripcion={proyecto.descripcion} completed={proyecto.completado} total={proyecto.total} SegundoNivel={proyecto.segundoNivel} PrimerNivel={proyecto.primerNivel} fechaInicio={proyecto.fechaInicio}></Card></Link>
                        </Col>
                    ))}
                </Row>
                <Row  style={{marginTop:"2rem"}}>
                    {secondRow.map(proyecto => (
                        <Col size={{xs:12,sm:12,md:12,lg:4}} key={proyecto.id}>
                            <Link className="n-link" to="/Project-details/1"><Card projects={true} categoria={proyecto.categoria} titulo={proyecto.nombre} descripcion={proyecto.descripcion} completed={proyecto.completado} total={proyecto.total} SegundoNivel={proyecto.segundoNivel} PrimerNivel={proyecto.primerNivel} fechaInicio={proyecto.fechaInicio}></Card></Link>
                        </Col>
                    ))}
                </Row>
        </Div>
        <Row m={{b:"1.5rem",t:"1.5rem"}}>
            <Col size={5} d="grid" style={{placeItems:"center"}} onClick={(a="-") => handleClick(a)}>
               {totalPages===1 ? <></>:<BotonPrimario  color="brick" texto="Anterior"/>} 
            </Col>

            <Col size={2} d="grid" style={{placeItems:"center"}}>
                <Text textSize={{xs:"tiny",sm:"caption",md:"heading",lg:"display1"}}>{page} de {totalPages}</Text>
            </Col>
            
            <Col size={5} d="grid" style={{placeItems:"center"}} onClick={(a="+") => handleClick(a)}>
                {totalPages===1 ? <></>:<BotonPrimario color="brick" texto="Siguiente"/>}
            </Col>
        </Row>
        </>
    )
}

export default ShowProjects
