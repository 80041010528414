import React from 'react'
import {Div,Row,Col} from 'atomize'

const ProgressBar = ({ completed,total }) => {
  const containerStyles = {
    borderRadius: 50
  }

  const fillerStyles = {
    borderRadius: 'inherit'
  }

  return (
    <Row>
      <Col size={12}>
        <Div bg="graypb" h="0.5rem" w="90%" style={containerStyles}>
      <Div bg="green" h="100%" w={`${(completed*100)/total}%`} style={fillerStyles}>
      </Div>
    </Div>
      </Col>
    </Row>
    
  );
};

export default ProgressBar;