import {React,useState,useEffect,useMemo} from 'react'
import {Text,Icon,Button,Div,Input} from 'atomize'


const BasicInput = ({callBack,trigger,place,text,textCaption,type,animated}) => {

  {/* Ayudaaaaaa */}


    const [showP,setShowP]=useState(false)
    const [value,setValue]=useState(place)

    const mailformat = useMemo(() => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ , []) 
    ;

    useEffect(() => {
        // Envia el valor del input al padre
        if(!trigger){
            callBack("","")
            return
        }
        if(!value && trigger){
            callBack("","Por favor llene el campo")
            return
        }
        if(type==="email" && !value.match(mailformat)){
            
            callBack("","Formato de correo incorrecto")
            return
        }
        if(type==="email" && value.match(mailformat)){
            callBack(value)
            setValue('')
            return
        }else if(type!=="email" && value){
            
            if(type==="password" && value.length<8){
               
                callBack("","Muy corta")
                return
            }else{
                
                callBack(value)
                setValue('')
            }
        }
        callBack("")
    },[trigger, value, type, mailformat, callBack]);
    


  var classLabelAnimated, classLabelNAnimated;
  
  if(animated){
    classLabelAnimated = "input-label d-none d-sm-block"
    classLabelNAnimated = "input-label-m d-sm-none"
  }else{
    
    classLabelAnimated = "input-label d-none"
    classLabelNAnimated = "input-label-m d-block"

  }

  return (
    <div className="form-input-wrapper mt-5 mx-2 mb-5">
      <div className="form-input">
        {/*Label estatica*/}
        <Text className={classLabelNAnimated} textSize={{ xs: 'subheader',md:'subheader' }}>{text}</Text>
        <label className="form-control-text-field form-inline justify-content-center mb-0">
          <div className="form-group flex-fill">
            <input  
              className="form-control w-100" 
              type={type !=="password" ? type:(showP ? "text" : "password")} 
              required
              value={value} 
              onChange={(event)=>{
                setValue(event.target.value);
              }}
              placeholder=" "
            />
            <Div>
              {type==="password" ? 
                <Button
                  pos="absolute"
                  onClick={() => setShowP(!showP)}
                  bg="transparent"
                  w="3rem"
                  top={{xs:"35%",md:"0"}}
                  right="0"
                  rounded={{ r: "md" }}
                >
                  <Icon
                    name={showP ? "EyeSolid" : "Eye"}
                    color={showP ? "danger800" : "success800"}
                    size="1rem"
                  />
                </Button>
                :
                <></>
                }
            </Div>
            {/*Label animada*/}
            <Text className={classLabelAnimated} textSize={{ xs: 'subheader',md:'subheader' }}>{text}</Text>
          </div>
        </label>
      </div>
      <div className="form-control-message">
          <div className="input-msg-caption mt-2">
            <Text textSize={{ xs: 'subheader',md:'subheader' }} 
                  textAlign = "left"
                  textColor = "gray"
                  fontFamily= "primary">
                  {textCaption}
            </Text>
          </div>
          <div className="input-error">{Error}</div>
        </div>
    </div>
  );

}

export default BasicInput
