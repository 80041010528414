import React from 'react'
import { Button, Icon, Text,Div } from "atomize";
import googleLogo from "../public/icons/google_G.svg"

const BotonSecundario = ({colorClass,texto,iconl,iconr,font,tamaño}) => {
    
    /*Variables para controlar las clases de bootstrap y modificar el botón*/
    var _colorClass = colorClass?colorClass:"btn-primary"
    var _tamaño =  tamaño?tamaño:"w-100"
    var _font = font?font:""
    
    var classMaker = "btn " + _colorClass + " w-100 " + _font;

    return (

        <div class={_tamaño}>
            
            <div class={classMaker}>

                {iconl && <Icon
                    name={iconl}
                    size={{ xs: '1rem',md:'1.5rem' }}
                    color="white"
                />}

                
                

                <Text textSize={{ xs: 'paragraph',md:'subheader' }}>
                    {texto}
                </Text>
                {iconr && <Icon
                    name={iconr}
                    size={{ xs: '1rem',md:'1.5rem' }}
                    color="white"
                />}
                
            </div>
            
        </div>
        
    
    )
}

export default BotonSecundario
