import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


//Pages
//import SitioConstruccion from './Pages/SitioConstruccion';

import Principal from './Pages/Principal';
import InicioSesion from './Pages/InicioSesion';
import Registrarse from './Pages/Registrarse'
import Registrarse2 from './Pages/Registrarse2'
import Registrado from './Pages/Registrado'
import UserHomePage from './Pages/UserHomePage';
import UserProjectsPage from './Pages/UserProjectsPage';
import UserWalletPage from './Pages/UserWalletPage'
import NuevoProyecto from './Pages/NuevoProyecto'
import ChooseProject from './Pages/ChooseProject'
import ProjectDetails from './Pages/ProjectDetails'
import Compare from './Pages/Compare'
import Done from './Pages/Done'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Principal/>
        </Route>
        <Route path='/iniciar'>
          <InicioSesion/>
        </Route>
        <Route path='/registro'>
          <Registrarse/>
        </Route>
        <Route path='/registro-datos-familiares'>
          <Registrarse2/>
        </Route>
        <Route exact path='/registrado/:id' children={<Registrado/>}/>
        <Route path='/startProject/:id' children={<NuevoProyecto/>}/>
        <Route exact path='/choose/:id' children={<ChooseProject/>}/>
        <Route exact path='/compare/:id' children={<Compare/>}/>
        <Route exact path='/done/:id' children={<Done/>}/>
        <Route exact path='/User/:id' children={<UserHomePage/>}/>
        <Route exact path='/User/:id/projects' children={<UserProjectsPage/>}/>
        <Route exact path='/User/:id/wallet' children={<UserWalletPage/>}/>
        <Route exact path='/Project-details/:id' children={<ProjectDetails/>}/>
        <Route path='*'><Principal/></Route>
        {
          //<Route path='*'><SitioConstruccion/></Route>
        }
      </Switch>
    </Router>
  );
}

export default App;
