import {React,useState,useEffect} from 'react'
import { Text, Div, Icon} from "atomize";

const DropdownProjectAtom = (
        {name,nivel1,nivel2,nivel3,trigger,callBack,menu,prev,place,country,tipoUsuario,empleado,size}) => {
    const [selected,setSelected]=useState(name)
    //const men=[{valor:"Aislada",caracteristicaId:"1",dominioId:"1"}, {valor:"Aislada",caracteristicaId:"1",dominioId:"2"}]
    const menuList = (
        <Div>
            {menu.map((value) => (
            <Text key={value.dominioId}
            className="dropdown-item"
            textSize={{ xs: 'caption',sm:'body',md:'paragraph',lg:"paragraph"}}
            cursor="pointer"
            d="block" 
            p={{ y: "0.25rem" }}
            onClick={() =>{
                setSelected(value.valor);
                if(country)
                    country(value.valor)
                if(nivel1)
                    nivel1(value.valor)
                if(nivel2)
                    nivel2(value.valor)
                if(nivel3)
                    nivel3(value.valor)
                if(tipoUsuario)
                    tipoUsuario(value.valor)
                if(empleado)
                    empleado(value.valor)
            }}
            >
                {value.valor}
            </Text>
            ))}
        </Div>
    );
    useEffect(() => {
        // Envia el valor del input al padre
        if(trigger && selected && selected !== name && selected !== prev){
            callBack(selected,name)
        }
    },[trigger,callBack,selected,name,prev]);
    const classMaker = "m-1 " + size
    const classMakerDrop = menu.length===0 ? "btn-drop dropdown-toggle disabled px-1 pt-1 py-2 mb-0 w-100":"btn-drop dropdown-toggle px-1 pt-1 py-2 mb-0 w-100"
    return (
        
    <div class={classMaker}>
        
            <div class="input-group flex-fill">
                <div class={classMakerDrop} type="input" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       
                        <Text textSize={{ xs: 'tiny',sm: 'caption',md:'paragraph' }} 
                            textAlign = "left"
                            textColor = "gray"
                            fontFamily= "primary"
                            m={{l:".1rem"}}>
                            {selected !==name ? selected:place ? place:name}
                        </Text>

                        
                        <Icon name="DownArrow" size="1rem" className="arrow-toggle"/>
                </div>
                <div class="dropdown-menu w-100">
                    {menuList}
                </div>
            </div>
            
      </div>
    )
}

export default DropdownProjectAtom
