import {React,useState,useEffect,useMemo} from 'react'
import {Text} from 'atomize'

const InputAll = ({text,callBack,trigger,place,type,prev,compliment,animated,textCaption,size}) => {

    const [error,setError]=useState("")
    const [value,setValue]=useState(place)
    const [showP,setShowP]=useState(false)
    const [show,setShow]=useState(false)
    const mailformat = useMemo(() => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ , []);
    

    useEffect(() => {
      // Envia el valor del input al padre
      if(type==="email"){
          if(trigger && value!==prev && value.match(mailformat))
          callBack(value,text,prev)
          return
      }else if(value && trigger && value!==prev){
          callBack(value,text,prev)
      }
  
  },[trigger, value, callBack,text,prev]);
    var classLabelAnimated, classLabelNAnimated;
  
    if(animated){
      classLabelAnimated = "input-label d-none d-sm-block"
      classLabelNAnimated = "input-label-m d-sm-none"
    }else{
      
      classLabelAnimated = "input-label d-none"
      classLabelNAnimated = "input-label-m d-block"
  
    }


    var classMaker
    size?(classMaker ="form-input-wrapper mx-1 " + size):(classMaker="form-input-wrapper mx-1")
    return (
        <div class={classMaker}>
          <div class="form-input">
            {/*Label estatica*/}
            <Text className={classLabelNAnimated} textSize={{ xs: 'subheader',md:'subheader' }}>{text}</Text>
            <label class="form-control-text-field form-inline justify-content-center mb-0">
              <div class="form-group flex-fill">
                <input  class="form-control px-1 py-2 w-100" 
                        type={type==="float" || type==="porcent" ? "number": type !=="password" ? type:(showP ? "text" : "password")}
                        step={type==="float" || type==="porcent" ? "0.01":""}
                        min={type==="float" || type==="porcent" || type==="number" ? "0":""}
                        max={type==="porcent" ? "100":""}
                        required
                        value={value} 
                        onChange={(event)=>{
                          if(event.target.value>=0 || type==="number")
                              setValue(event.target.value);
                          if(type!=="number"){
                              setValue(event.target.value);
                          }
                        }}
                        onFocus={()=>{
                          setShow(true)
                        }}
                        onBlur={()=>{
                          setShow(false)
                          if(type==="email" && value && !value.match(mailformat)){
                              setError("Formato de Correo incorrecto")
                          }else{setError("")}
                          
                        }}
                        placeholder=" "/>
                {/*Label animada*/}
                <Text className={classLabelAnimated} textSize={{ xs: 'subheader',md:'subheader' }}>{text}</Text>
              </div>
            </label>
          </div>
          <div class="form-control-message">
              <div class="input-msg-caption mt-2">
                <Text textSize={{ xs: 'subheader',md:'subheader' }} 
                      textAlign = "left"
                      textColor = "gray"
                      fontFamily= "primary">
                      {textCaption}
                </Text>
              </div>
              <div class="input-message">{error}</div>
            </div>
        </div>
    )
}

export default InputAll
 