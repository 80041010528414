import {React} from 'react'
import {Tag,Icon} from 'atomize'

const MTag = ({text,color,textColor,borderColor,size,iconl,iconr}) => {

  return(
    <Tag
      bg={color?color:`redBG`}
      border="1px solid"
      borderColor={borderColor?borderColor:`brick`}
      textColor={textColor?textColor:`brick`}
      textSize={{ xs: 'tiny',sm:'caption',md:'body'}}
      rounded="circle"
      fontFamily="primary"
      w="100%"
      justify="left"
      >
          {text}
          {iconr && <Icon   name={iconr} 
                  size="1rem"
                  className="arrow-toggle"
                  color={textColor?textColor:`brick`}
          />}
    </Tag>
  )

}

export default MTag