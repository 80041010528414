import React from 'react'
import {Div,Row, Col, Text, Icon, Container,Image} from 'atomize'
import builar from '../public/builar.svg'
import { Link } from 'react-router-dom';

const NavBarLogo = ({userName}) => {
    return (
        
        <nav class="navbar navbar-marketing navbar-expand-lg bg-white navbar-light mb-4" style={{backgroundColor:'white',shadow:"3",paddingTop:".9rem",paddingBottom:".9rem"}}>
            <Container>
                
                
                <Row>
                    <Col size={{ xs:4,sm:4,md:3}}>
                        <Link className="nav-link" to="/"><a class="navbar-marketing navbar-brand svg text-primary m-0 p-0"><img class="svg" src={builar} alt="builar-logo" /></a></Link>
                    </Col>
                    
                    <Col size={{ xs:8,sm:8,md:9}} d="grid" style={{placeItems:"end"}}>

                            {userName ?
                            <Text
                            fontFamily="code" textSize={{ xs: 'caption',sm:'title',md:'paragraph'}}
                            textColor="brick"
                            > {<Icon m={{r:"0.5rem"}} name="UserCircle" color="brick" size={{ xs: '2rem',sm:'2rem',md:'2.5rem'}} />}
                            ¡Hola, {userName==="1" ? "Arturo Lares":userName}!</Text>:<></>
                            }
                          
                    </Col>
                </Row>
                
            </Container>
        </nav>
    )
}

export default NavBarLogo
