import React from 'react'
import {Div, Text,Row,Col} from 'atomize'
import ProgressBar from './ProgressBar'
import Tags from './Tags'

const Card = ({projects,completed,total,SegundoNivel,PrimerNivel,fechaInicio,descripcion,titulo,categoria}) => {
    return (
        <Col className="col-lg-4 mb-3">
            
            <a class="card lift h-100" href="#!">
                <img class="card-img-top" src="https://cf.bstatic.com/images/hotel/max500/211/211169617.jpg" alt="..."/>
                <div class="card-body mt-1">
                    <Text className="card-title" textColor="blackText" fontFamily="code" textColor="brick" tag="h1" textSize={{xs:"subheader",sm:"title",md:"heading"}}>
                        {titulo}
                    </Text>
                    <Text className="card-text" textColor="blackText"  fontFamily="secondary" style={{lineHeight:"normal"}} tag="p" d="flex"textSize={{xs:"body",sm:"body",md:"body"}}>
                        {descripcion.length<80 ? descripcion:descripcion.substring(0, 79) + "..."}
                    </Text>
                    
                    <div class="mt-3">
                        <p class="card-text">Etapa {completed} de {total}</p>
                        <ProgressBar completed={completed} total={total}/>   
                    </div>

                </div>
                <div class="card-footer bg-transparent border-top">
                    <div class="small card-text mb-2">{SegundoNivel}, {PrimerNivel} - desde {fechaInicio}</div>
                    <Div d="flex" align="center" justify="left" flexWrap="wrap">
                        <Tags size={projects ? "90":null} text={categoria}/>  
                        <Tags size={projects ? "90":null} text="centro c."/>  
                        <Tags size={projects ? "90":null} text="programa impulso"/>
                    </Div>
                </div>
            </a>

        </Col>
        
    )
}

export default Card
