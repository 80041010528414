import {React} from 'react'
import {Container,Row,Col,Text,Div,Icon} from 'atomize'
import NavBarLogo from '../Molecules/NavBarLogo'
import NavBarUser from '../Molecules/NavBarUser'
import BotonPrimario from '../Atoms/BotonPrimario'
import {useParams } from 'react-router-dom';
import Footer from '../Molecules/Footer'
import MTag from '../Atoms/_Tags'

const UserWalletPage = () => {
    const {id}=useParams();

    const proyectos=[
        {
            nombre:"Lomas del Caroní",
            total:10,
            completado:2,
            id:"proyecto-123",
            movimientos:[{
                estado:"Completado",
                cantidad:"250 USD",
                id:1,
                desc:"Adquisición de bloques para construcción",
                fecha:"12/12/2021"
            },
        {
                estado:"En espera",
                cantidad:"567.56 USD",
                id:2,
                desc:"Adquisición de bloques para construcción",
                fecha:"12/12/2021"
            }]
        },
        {
            nombre:"Lomas del Caroní",
            total:14,
            completado:6,
            id:"proyecto-456",
            movimientos:[{
                estado:"Completado",
                cantidad:"250 USD",
                id:3,
                desc:"Adquisición de bloques para construcción",
                fecha:"12/12/2021"
            },
        {
                estado:"en espera",
                cantidad:"567.56 USD",
                id:4,
                desc:"Adquisición de bloques para construcción",
                fecha:"12/12/2021"
            }]
        }
    ]
    const objectAxiosP=[{
        idMovimiento:1,
        Descripcion:"Depósito",
        Cantidad:"120 USD",
        estado:"aprobado"
    },
    {
        idMovimiento:43,
        NombreProyecto:"Lomas del Carolina",
        Descripcion:"Etapa",
        estado:"aprobado"
    },{
        idMovimiento:423,
        NombreProyecto:"Lomas del Carolina",
        Descripcion:"Etapa",
        estado:"no aprobado"
    }]

    const objectAxios={
        Nombre:"Arturo Lares",
        DNI:"V-8935243",
        Teléfono:"0414-821345",
        Email:"arturolares@gmail.com",
        Ubicación:"Nueva Esparta, Venezuela",
        saldo:"32535 USD"
    }
    const config={
         w:"100%",
         border:"1px solid",
         borderColor:"lightgray",
         rounded:"lg",
         shadow:"2"
    }

    return (
        <>
            <NavBarLogo userName={id}/>
            <Container>
                <Div className="card">
                    <NavBarUser id={id} selected="3"/>
                    <Row m={{t:"2rem",b:"2rem",x:{md:"5rem",sm:"1rem",xs:"1rem"}}}>

                        <Col size={{xs:12,sm:12,md:12,lg:4}}>
                            <Div>
                                <Text 
                                textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}}
                                textColor="black"
                                fontFamily="code"
                                >Saldo Disponible</Text>
                                <Div {...config} className="card p-3" p={{sm:"0rem",md:"1rem"}} d="grid" style={{placeItems:"center"}}>
                                    <Text 
                                        textSize={{ xs: 'title',sm:'title',md:'heading',lg:"display1"}}
                                        textColor="blue"
                                        fontFamily="code"
                                        m={{ xs: '0.4rem',sm:'0.4rem',md:'1rem',lg:"1rem"}}>
                                    {objectAxios.saldo}</Text>
                                    <Div m="1rem" className="w-100"><BotonPrimario btnColor="btn-primary w-100 m-0" texto="Depositar" /></Div>
                                    
                                </Div>
                            </Div>

                            <Div m={{t:"2rem"}} >
                                <Text 
                                textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}}
                                textColor="black"
                                fontFamily="code"
                                >Movimientos</Text>
                                <Div className="card p-3" {...config} p={{sm:"0rem",md:"1rem"}} >
                                    {
                                        objectAxiosP.map((movimiento)=>{
                                            return(
                                            
                                                
                                                    <Row key={movimiento.idMovimiento} p={{b:".5rem"}}>
                                                        <Col d="grid" style={{placeItems:"center"}} size={12}>
                                                            <MTag text={`${movimiento.Descripcion} #${movimiento.idMovimiento} ${movimiento.NombreProyecto ? "proyecto "+movimiento.NombreProyecto:movimiento.Cantidad}`}
                                                                  color={movimiento.estado==="aprobado" ? "greenBG":"brickBt"}
                                                                  textColor={movimiento.estado==="aprobado" ? "green":"brick"}
                                                                  borderColor="none"
                                                                  iconr="RightArrow"
                                                            ></MTag>
                                                            
                                                        </Col>
                                                    </Row>
                                            )
                                        })
                                    }

                                    <Div m={{t:"1rem"}} d="grid" style={{placeItems:"center"}}><BotonPrimario btnColor="btn-primary w-100 m-0" color="brick" texto="Ver Todo" /></Div>
                                </Div>
                                
                            </Div>
                            
                        </Col>
                        <Col size={{xs:12,sm:12,md:12,lg:8}}>
                            <Div  m={{b:"1.5rem"}}>
                                <Text 
                                textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}}
                                textColor="black"
                                fontFamily="code"
                                >Transacciones de proyectos</Text>
                               <Div className="accordion accordion-faq" id="Projects" border="1px solid" borderColor="lightgray" shadow="2">
                                    {proyectos.map((proyecto)=>{
                                        return (
                                            <>
                                            
                                            <Div className="card accordion-faq-item">
                                                <a class="card-header collapsed" id={proyecto.id} data-toggle="collapse" data-target={'#Collapse-'+proyecto.id} aria-expanded="false" aria-controls={'Collapse-'+proyecto.id} href="javascript:void(0);">
                                                    <Div className="accordion-faq-item-heading">
                                                    <Row>
                                                        <Col size={9}>
                                                            <Row p={{t:"0.5rem"}}>
                                                                <Col size={12}>
                                                                    <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="code" textColor="blue">{proyecto.nombre}</Text>
                                                                </Col>
                                                            
                                                                <Col size={12}>
                                                                    <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary">{proyecto.completado} de {proyecto.total} etapas completadas</Text>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col size={3} p={{t:"0.5rem"}}>
                                                            <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary" textDecor="underline" textColor="brick">Ver Detalles</Text>
                                                        </Col>
                                                    </Row>
                                                        <Icon className="feather feather-chevron-down accordion-faq-item-heading-arrow" name="DownArrow" size="24px"></Icon>    
                                                    </Div>
                                                </a>
                                                <Div className="collapse" id={'Collapse-'+proyecto.id} aria-labelledby={"authHeading"+proyecto.id} data-parent="#Projects">
                                                    <Div>
                                                        {
                                                            proyecto.movimientos.map((movimiento) => (
                                                                <Row key={movimiento.id} m={{t:"0.5rem",b:"0.5rem"}} p="1rem" className="item">
                                                                    
                                                                    <Col size={8} >
                                                                        <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="code" textColor={movimiento.estado==="Completado" ? "green":"red"}>{movimiento.estado}</Text>
                                                                        <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary" >{movimiento.desc}</Text>
                                                                    </Col>
                                                                    <Col size={4} >
                                                                        <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary">Cantidad</Text>
                                                                        <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="code">{movimiento.cantidad}</Text>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }  
                                                    </Div>
                                                </Div>
                                            </Div>
                                            </>
                                        )
                                    })}
                                
                                </Div>
                            </Div>
                        </Col>
                        
                        
                    </Row>

                </Div>
            </Container>
            <Footer/>
         </>
    )
}

export default UserWalletPage
