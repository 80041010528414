import {React,useState,useEffect} from 'react'
import {Container,Row,Col,Text,Div} from 'atomize'
import NavBarLogo from '../Molecules/NavBarLogo'
import Footer from '../Molecules/Footer'
import { useLocation,useParams } from 'react-router-dom';
import BotonPrimario from '../Atoms/BotonPrimario';
import ProjectSelectModal from '../Molecules/ProjectSelectModal'
import { useHistory } from "react-router-dom";

const ChooseProject = () => {
    let history = useHistory();
    const location = useLocation();
    const [data,setData]=useState({...location.state.data,select:[],search:[]})
    const [showModal,setShowModal]=useState([])
    const {id}=useParams();
    const handleSelect=(modeloId)=>{
        if(data.select.length<=3){
            let aux=data.select.indexOf(modeloId)
            let arr=data.select
            if(aux!==-1){
                arr=arr.filter(id => id!==modeloId)
            }else{
                if(data.select.length<3)
                    arr.push(modeloId)
            }
            setData(dat=>{return{...dat,select:arr}})
            console.log(arr)
        }
    }
    var d=`Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum
            dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.
            Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum
            dolor sit amet.`
    useEffect(()=>{
        //consulta a la bd para sacr los proyectos que cumplan con las condiciones
        let aux=[
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:1,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:2,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:3,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:4,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:5,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:6,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:7,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:8,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:9,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2}]
        setData(data=>{return{...data,search:aux}})
        let bool=[]
        aux.forEach((a)=>bool.push(false))
        setShowModal(show=>{return bool})
        
    },[])

  return (
    <>
      <NavBarLogo userName={id}/>
      <Container>
      <div class="card pricing py-4 px-0">
          <nav>
              <Row h={{ xs: '3rem',sm:'3rem',md:'3rem',lg:"3rem"}} 
                    borderColor="grayBorder" 
                    className="border-bottom px-0 mx-0">
                  
                  <Col size={12} d="grid" style={{placeItems:"center"}}>
                      <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} fontFamily="primary">Escoge 3 opciones para comparar</Text>
                  </Col>
                  <Col size={12} className="pb-0 mb-0 px-0" d="flex" align="flex-end">
                      <Div w="100%" h=".3rem" className="pb-0 mb-0" >
                          <Div w="100%" h="100%" bg="blue"></Div>
                      </Div>
                  </Col>
              
              </Row>
          </nav>
          
          <Container>
            <Row m={{y:"1.5rem",x:"1.5rem"}}>
                    

                    <Col size={{ xs: 12,sm:12,md:3,lg:3}} className="card card-pricing" 
                         p={{x:{md:"2rem",sm:"1rem"},y:{md:"2rem",sm:"1rem"}}}
                         border="1px solid" borderColor="lightgray" shadow="3" rounded="1" >
                        <Row>
                            <Col size={12} d="grid" style={{placeItems:"center"}} p={{b:"1.5rem"}}>
                                <Div className="newPIcon">
                                <span className="material-icons" >
                                    {data.categoriaIcon}
                                </span> 
                                </Div>
                                <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} fontFamily="code" textColor="brick">{data.categoriaName}</Text>   
                            </Col>
                        </Row>

                        <Div>
                            {
                                data.caracteristicas.map((carac,i)=>{
                                    return (
                                        <Row key={i}>
                                            <Col size={12}>
                                                <Text  className="item"  textSize={{ xs: 'caption',sm:'caption',md:'paragraph',lg:"paragraph"}}fontFamily="primary">
                                                    {`${carac[0]}:  ${carac[1]===true ? "Si":carac[1]===false ? "No":carac[1]}`}
                                                </Text>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Div>
                    </Col>
                    
                    {}
                    
                    <Col size={{ xs: 12,sm:12,md:9,lg:9}} >
                        <Col size={12} >
                            <Div >

                                <Row>
                                    
                                {
                                    data.search.map((modelo,index)=>(
                                        
                                        <Col size={{ xs: 12,sm:12,md:12,lg:4}} key={modelo.modeloId}>
                                            <ProjectSelectModal
                                                selected={data.select.indexOf(modelo.modeloId)===-1 ? false:true}
                                                inf={modelo}
                                                isOpen={showModal[index]}
                                                onClose={() => {
                                                    let aux=[]
                                                    showModal.forEach((item)=>{aux.push(false)})
                                                    setShowModal(aux)}}
                                                onSelect={handleSelect}
                                                />
                                            <Div
                                            className="card lift h-100"
                                            borderColor={data.select.indexOf(modelo.modeloId)===-1 ? "none":"green"} 
                                            onClick={()=>{
                                                let aux=[]
                                                showModal.forEach((item)=>{aux.push(false)})
                                                aux[index]=true
                                                setShowModal(aux)
                                            }} >
                                                <img class="card-img-top" src="https://cf.bstatic.com/images/hotel/max500/211/211169617.jpg" alt="..."></img>
                                                <Div p="1rem">
                                                    <Text textColor="brick" fontFamily="code" textSize={{ xs: 'caption',sm:'body',md:'paragraph',lg:"paragraph"}}>{modelo.name}</Text>
                                                    <Row>
                                                        <Col size={6}>
                                                            <Text textSize={{ xs: 'caption',sm:'body',lg:"paragraph"}}>Etapas: {modelo.nmroEtapas}</Text>
                                                            <Text textSize={{ xs: 'caption',sm:'body',lg:"paragraph"}}>Tipo: {modelo.tipo}</Text>
                                                        </Col>
                                                        <Col size={6}>
                                                          <Text textSize={{ xs: 'caption',sm:'body',lg:"paragraph"}}>Habitaciones: {modelo.habitaciones}</Text>
                                                          <Text textSize={{ xs: 'caption',sm:'body',lg:"paragraph"}}>Baños: {modelo.baños}</Text>
                                                        </Col>
                                                    </Row>
                                                </Div>
                                            </Div>
                                        </Col>
                                    ))
                                }
                                </Row>
                            
                            </Div>
                            
                        </Col>
                        <Col size={12} >
                            <Row m={{t:"1rem",b:"1rem"}}> 
                                <Col size={{ xs: 12,sm:12,md:8,lg:8}} >
                                <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"title"}} fontFamily="primary">Has escogido {data.select.length} de 3:</Text>
                            </Col>
                            <Col size={{ xs: 12,sm:12,md:4,lg:4}} 
                            d="grid" style={{placeItems:"center"}}
                            onClick={()=>{
                                if(data.select.length===3){
                                    history.push(`/compare/${id}`,{selectedIds:data.select});
                                }
                            }}>
                                <BotonPrimario texto="Comparar" color="brick" tamaño="l"/>
                            </Col>
                            </Row>
                            
                        </Col>
                    </Col>
                </Row>
            
          </Container>
          
      </div>
      </Container>
      <Footer/>
  </>
  )
}

export default ChooseProject
