import {React,useState,useEffect} from 'react'
import {Container,Row,Col,Text, Div} from 'atomize'
import NavBarLogo from '../Molecules/NavBarLogo'
import DropdownProjectAtom from '../Atoms/DropdownProjectAtom'
import SwitchAtom from '../Atoms/SwitchAtom'
import BotonSecundario from '../Atoms/BtnSecondary'
import { useHistory } from "react-router-dom";
import InputAll from '../Atoms/InputAll'
import { useMediaQuery } from 'react-responsive';


const Registrarse = () => {
    let history = useHistory();
    const [inputs,setInputs]=useState({inputs:[],initialize:true,drops:[],opciones:[],estadoCivil:[],nivelEducativo:[],tipoVivienda:[],Empleado:[],tipoUsuario:"",emp:"",banco:false})
    const [click,setClick]=useState(false)
    const pages=["Completa tus datos","Numeros 2","ya casi casi"]
    const getMenu=(idDrop,idPais)=>{
        console.log(idDrop,idPais)
        if(idDrop==="NO"){
            return []
        }

        if(idPais){
            let men=inputs.opciones.filter(({caracteristicaId})=>caracteristicaId==idDrop)
            console.log(men)
            men=men.filter(({dominioId})=>dominioId==idPais)
            console.log(men)
            return men
        }

        let menu=inputs.opciones.filter(({caracteristicaId})=>caracteristicaId===idDrop)

        return menu;
    }
    const banca=(value)=>{
        setInputs({...inputs,banco:value})
    }
    const country=(value)=>{
        let index=inputs.opciones.find(({valor})=>valor===value).id
        setInputs({...inputs,idPais:index,idEstado:"",idMunicipio:""})
    }
    const nivel1=(value)=>{
        let index=inputs.opciones.find(({valor})=>valor===value).id
        setInputs({...inputs,idEstado:index,idMunicipio:""})
        console.log(inputs.idEstado)
    }
    const nivel2=(value)=>{
        let index=inputs.opciones.find(({valor})=>valor===value).id
        setInputs({...inputs,idMunicipio:index,idParroquia:""})
    }
    const nivel3=(value)=>{
        let index=inputs.opciones.find(({valor})=>valor===value).id
        setInputs({...inputs,idParroquia:index})
    }
    const tipoUsuario=(value)=>{
        setInputs({...inputs,tipoUsuario:value})
        console.log(value)
    }
    const empleado=(value)=>{
        setInputs({...inputs,emp:value})
        console.log(value)
    }
    const callBack=(value,name)=>{
        let aux=inputs.inputs
        let control=[name,value]
        if(aux.find(({0:name})=>name===control[0])){
            for (let index = 0; index < aux.length; index++) {
                const element = aux[index];

                if(element[0]===name){
                    (aux[index])[1]=value
                }else{
                    continue
                }
                
            }
        }else{
            aux.push([name,value])
            
        }
        setInputs({...inputs,inputs:aux})
        //aqui se tendria que validar que todas las entradas esten perooooo sikeeee
        //redirect

        setClick(false)
        console.log(inputs)
        history.push(`/registro-datos-familiares`);
    }

    useEffect(()=>{
        let drops=[
            {id:"1",nombre:"País",tipo:"CHAR",grupoId:"1"},
            {id:"2",nombre:"D.I",tipo:"CHAR",grupoId:"1"},
            {id:"3",nombre:"Sexo",tipo:"CHAR",grupoId:"1"},
            {id:"4",nombre:"Estado/Departamento",tipo:"CHAR",grupoId:"1"},
            {id:"5",nombre:"Municipio/Localidad",tipo:"CHAR",grupoId:"1"},
            {id:"6",nombre:"Parroquia/Corregimiento o Comuna",tipo:"CHAR",grupoId:"1"},
            {id:"7",nombre:"Tipo de Usuario",tipo:"CHAR",grupoId:"1"},
            {id:"8",nombre:"Banco",tipo:"CHAR",grupoId:"1"}
        ]//ESTO ES FIJO
        let opciones=[
        {id:"33",valor:"Natural",caracteristicaId:"7",dominioId:"1",formato:""},
        {id:"34",valor:"Jurídico",caracteristicaId:"7",dominioId:"1",formato:""},
            {id:"1",valor:"Venezuela",caracteristicaId:"1",dominioId:"1",formato:""},
            {id:"2",valor:"Colombia",caracteristicaId:"1",dominioId:"2",formato:""},
        {id:"3",valor:"CC",caracteristicaId:"2",dominioId:"2",formato:`/^\d{5,15}$/`},
        {id:"4",valor:"CE",caracteristicaId:"2",dominioId:"2",formato:`/^\d{1,9}$/`},
        {id:"5",valor:"NIT",caracteristicaId:"2",dominioId:"2",formato:`/(^[0-9]+-{1}[0-9]{1})/`},
        {id:"6",valor:"PA",caracteristicaId:"2",dominioId:"2",formato:`/^(\d{5,15})|([A-Z]{2}[0-9]{6,10})$/`},
        {id:"7",valor:"E",caracteristicaId:"2",dominioId:"1",formato:`/^[0-9]{5,15}$/`},
        {id:"8",valor:"J",caracteristicaId:"2",dominioId:"1",formato:`/^([0-9]{9})-([0-9]{1}$)/`},
        {id:"9",valor:"P",caracteristicaId:"2",dominioId:"1",formato:`/^[0-9]{8,15}$/`},
        {id:"10",valor:"V",caracteristicaId:"2",dominioId:"1",formato:`/^[0-9]{5,10}$/`},
            {id:"11",valor:"M",caracteristicaId:"3",dominioId:"1",formato:""},
            {id:"12",valor:"F",caracteristicaId:"3",dominioId:"2",formato:""},
        {id:"13",valor:"Nueva Esparta",caracteristicaId:"4",dominioId:"1",formato:""},
        {id:"14",valor:"Bolívar",caracteristicaId:"4",dominioId:"1",formato:""},
        {id:"15",valor:"Antioquia",caracteristicaId:"4",dominioId:"2",formato:""},
        {id:"16",valor:"Bogotá",caracteristicaId:"4",dominioId:"2",formato:""},
            {id:"17",valor:"Arismendi",caracteristicaId:"5",dominioId:"13",formato:""},
            {id:"18",valor:"Antolín del Campo",caracteristicaId:"5",dominioId:"13",formato:""},
            {id:"19",valor:"Heres",caracteristicaId:"5",dominioId:"14",formato:""},
            {id:"20",valor:"Angostura",caracteristicaId:"5",dominioId:"14",formato:""},
            {id:"21",valor:"Medellín",caracteristicaId:"5",dominioId:"15",formato:""},
            {id:"22",valor:"Abejorral",caracteristicaId:"5",dominioId:"15",formato:""},
            {id:"23",valor:"Santa Fe",caracteristicaId:"5",dominioId:"16",formato:""},
            {id:"24",valor:"San Cristóbal",caracteristicaId:"5",dominioId:"16",formato:""},
        {id:"25",valor:"Arismendi",caracteristicaId:"6",dominioId:"17",formato:""},
        {id:"26",valor:"Antolín del Campo",caracteristicaId:"6",dominioId:"18",formato:""},
        {id:"27",valor:"Agua salada",caracteristicaId:"6",dominioId:"19",formato:""},
        {id:"28",valor:"San Francisco",caracteristicaId:"6",dominioId:"20",formato:""},
        {id:"29",valor:"San sebastian de palmitas",caracteristicaId:"6",dominioId:"21",formato:""},
        {id:"30",valor:"Pantanillo",caracteristicaId:"6",dominioId:"22",formato:""},
        {id:"31",valor:"Guasabra",caracteristicaId:"6",dominioId:"23",formato:""},
        {id:"32",valor:"UPZ La Gloria",caracteristicaId:"6",dominioId:"24",formato:""},
        {id:"35",valor:"Banco de Venezuela",caracteristicaId:"8",dominioId:"1",formato:""},
        {id:"36",valor:"Banesco",caracteristicaId:"8",dominioId:"1",formato:""},
        {id:"37",valor:"Banco Agrario de Colombia",caracteristicaId:"8",dominioId:"2",formato:""},
        {id:"38",valor:"Banco AV Villas",caracteristicaId:"8",dominioId:"2",formato:""}

        ]//ESTO SE SACA DE LA BD 
        
        let estadoCivil=[{valor:"Casado",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Soltero",caracteristicaId:"1",dominioId:"1",formato:""},{valor:"Viudo",caracteristicaId:"1",dominioId:"1",formato:""}]
        let nivelEducativo=[{valor:"Básico",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Educación media",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Universitario",caracteristicaId:"0",dominioId:"0",formato:""}]
        let tipoVivienda=[{valor:"Vivienda Propia",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Alquilado",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Vivienda de un familiar",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Vivienda de un conocido",caracteristicaId:"0",dominioId:"0",formato:""}]
        let Empleado=[{valor:"Empleado",caracteristicaId:"0",dominioId:"0",formato:""},{valor:"Trabajador Independiente",caracteristicaId:"0",dominioId:"0",formato:""}]
       
        setInputs(data=> {return {...data,drops,opciones,estadoCivil,nivelEducativo,tipoVivienda,Empleado}})
    },[inputs.initialize])

    
    return (
        <>
        <NavBarLogo/>
        <Container>
        <div class="card pricing h-100 py-4 px-0">
            <nav>
                <Row h={{ xs: '3rem',sm:'3rem',md:'3rem',lg:"3rem"}} 
                        borderColor="grayBorder" 
                        className="border-bottom px-0 mx-0">
                    
                    <Col size={12} d="grid" style={{placeItems:"center"}}>
                        <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} fontFamily="primary">Paso 1 de 3: {pages[0]}</Text>
                    </Col>
                    <Col size={12} className="pb-0 mb-0 px-0" d="flex" align="flex-end">
                        <Div w="100%" h=".3rem" className="pb-0 mb-0" >
                            <Div w="33%" h="100%" bg="blue"></Div>
                        </Div>
                    </Col>
                
                </Row>
            </nav>
        
            <Div className="card-body"
                    m={{ x: { xs: '1rem', md: '4rem' }, y: { xs: '1rem', md: '2rem' }}}>
            <Text 
                textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}}  
                fontFamily="code">
                    Datos de ubicación
            </Text>

            <Row>
                <Col size={{ xs: 12,sm:12,md:6,lg:6}}>
                    <DropdownProjectAtom size="w-100" country={country} name="País" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="País") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="País")[1]} menu={getMenu(inputs.drops.find((drop)=>drop.nombre==="País") ? inputs.drops.find((drop)=>drop.nombre==="País").id:[])}/>
                </Col>
                <Col size={{ xs: 12,sm:12,md:6,lg:6}}>
                    <DropdownProjectAtom size="w-100" nivel1={nivel1} name="Estado/Departamento" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Estado/Departamento") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Estado/Departamento")[1]} menu={inputs.idPais ? getMenu((inputs.drops.find((drop)=>drop.nombre==="Estado/Departamento") ? inputs.drops.find((drop)=>drop.nombre==="Estado/Departamento").id:"NO"),inputs.idPais):[]}/>
                </Col><Col size={{ xs: 12,sm:12,md:6,lg:6}}>
                    <DropdownProjectAtom size="w-100" nivel2={nivel2} name="Municipio/Localidad" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Municipio/Localidad") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Municipio/Localidad")[1]} menu={inputs.idEstado ? getMenu((inputs.drops.find((drop)=>drop.nombre==="Municipio/Localidad") ? inputs.drops.find((drop)=>drop.nombre==="Municipio/Localidad").id:"NO"),inputs.idEstado):[]} />
                </Col><Col size={{ xs: 12,sm:12,md:6,lg:6}}>
                    <DropdownProjectAtom size="w-100" nivel3={nivel3} name="Parroquia/Corregimiento o Comuna" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Parroquia/Corregimiento o Comuna") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Parroquia/Corregimiento o Comuna")[1]} menu={inputs.idMunicipio ? getMenu((inputs.drops.find((drop)=>drop.nombre==="Parroquia/Corregimiento o Comuna") ? inputs.drops.find((drop)=>drop.nombre==="Parroquia/Corregimiento o Comuna").id:"NO"),inputs.idMunicipio):[]}/>
                </Col>
                <Col size={12}>
                    <InputAll  size={useMediaQuery({ query: `(min-width: 1060px)`})? "w-75":"w-100" } text="Dirección" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Dirección") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Dirección")[1]}/>
                </Col>      
            </Row>

            <Text
                m={{y:"2rem"}}
                textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}}  
                fontFamily="code">
                    Datos personales
            </Text>

            <Row className="my-5" >
                <Col size={{ xs: 12,sm:12,md:4,lg:4}}>
                    <InputAll text="Nombres" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Nombres") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Nombres")[1]}/>
                </Col>
                <Col size={{ xs:12,sm:12,md:4,lg:4}}>
                    <InputAll text="Apellidos" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Apellidos") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Apellidos")[1]}/>
                </Col>         
                <Col size={{ xs: 12,sm:12,md:4,lg:4}}>
                    <DropdownProjectAtom size="mt-4" name="Sexo" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Sexo") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Sexo")[1]} menu={getMenu(inputs.drops.find((drop)=>drop.nombre==="Sexo") ? inputs.drops.find((drop)=>drop.nombre==="Sexo").id:[])}/>
                </Col>
            </Row>

            <Row className="my-5">
                <Col size={{ xs:12,sm:12,md:6,lg:4}} d="flex" justify="left" align="flex-start">
                    <DropdownProjectAtom size="w-100 mt-4 mr-3" tipoUsuario={tipoUsuario} name="Tipo de Usuario" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Tipo de Usuario") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Tipo de Usuario")[1]} menu={getMenu(inputs.drops.find((drop)=>drop.nombre==="Tipo de Usuario") ? inputs.drops.find((drop)=>drop.nombre==="Tipo de Usuario").id:[])}/>
                    <DropdownProjectAtom size="mt-4"place="D.I"name="D.I" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="D.I") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="D.I")[1]} menu={getMenu((inputs.drops.find((drop)=>drop.nombre==="D.I") ? inputs.drops.find((drop)=>drop.nombre==="D.I").id:"NO"),inputs.idPais)}/>
                </Col>
                <Col size={{ xs:12,sm:12,md:6,lg:4}} justify="left"  >
                    <InputAll size="w-100" text="Documento Identidad" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Documento Identidad") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Documento Identidad")[1]}/>     
                </Col>
            </Row>


            {inputs.tipoUsuario && inputs.tipoUsuario=="Natural" ?
            <>
            <Row className="my-5">
                <Col size={{ xs: 6,sm:6,md:6,lg:3}}>
                    <DropdownProjectAtom  name="Estado civil" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Estado civil") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Estado civil")[1]} menu={inputs.estadoCivil}/>
                </Col>
                <Col size={{ xs: 6,sm:6,md:6,lg:3}}>
                    <DropdownProjectAtom  name="Nivel Educativo" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Nivel Educativo") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Nivel Educativo")[1]} menu={inputs.nivelEducativo}/>
                </Col>
                <Col size={{ xs: 6,sm:6,md:6,lg:3}}>
                    <DropdownProjectAtom size={{lg:"w-75",md:"w-75",xs:"w-100"}}  name="Tipo de vivienda actual" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Tipo de vivienda actual") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Tipo de vivienda actual")[1]} menu={inputs.tipoVivienda}/>
                </Col>
                <Col size={{ xs: 12,sm:12,md:6,lg:3}}>
                    <DropdownProjectAtom  name="Trabajo" empleado={empleado} callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Trabajo") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Trabajo")[1]} menu={inputs.Empleado}/>
                </Col>
            </Row>
            
            <Row className="my-5">
            {inputs.emp && inputs.emp==="Empleado" ? 
                <Col size={{ xs: 12,sm:12,md:12,lg:7}}>
                    <InputAll text="Empresa Empleadora" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Empresa Empleadora") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Empresa Empleadora")[1]}/>
                </Col>
                
                :<>
                </>
            }
                <Col size={{ xs: 12,sm:12,md:4,lg:4}}>
                    <InputAll text="Ingresos Mensuales" callBack={callBack} trigger={click} type="float" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Ingresos Mensuales") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Ingresos Mensuales")[1]}/>
                </Col>
                <Col size={{ xs: 12,sm:12,md:4,lg:4}}>
                    <InputAll text="Egresos Mensuales" callBack={callBack} trigger={click} type="float" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Egresos Mensuales") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Egresos Mensuales")[1]}/>
                </Col>
                <Col size={{ xs: 12,sm:12,md:4,lg:4}}>
                    <InputAll  text="Porcentaje Libre" compliment="(Porcentaje de sus ingresos dirigidos a sus proyectos %)" callBack={callBack} trigger={click} type="porcent" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Porcentaje Libre") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Porcentaje Libre")[1]}/>
                </Col>
            </Row>
                
            <Col size={{ xs: 12,sm:12,md:3,lg:3}} d="grid" style={{placeItems:"start"}} >
                <SwitchAtom text="Es cabeza de familia?" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Es cabeza de familia?") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Es cabeza de familia?")[1]}/>
            </Col> 
            <Col m={{b:"5rem"}} size={{ xs: 12,sm:12,md:4,lg:4}} d="grid" style={{placeItems:"start"}}>
                <SwitchAtom text="Posee antecedentes penales?" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Posee antecedentes penales?") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Posee antecedentes penales?")[1]}/>
            </Col>
            {inputs.idPais && inputs.idPais==="2" && inputs.tipoUsuario==="Natural" ?
            <>
            <Col size={{ xs: 12,sm:12,md:6,lg:6}}>
                <SwitchAtom text="Pertenece a una comunidad indigena?" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Pertenece a una comunidad indigena?") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Pertenece a una comunidad indigena?")[1]}/>
            </Col> 
            <Col size={{ xs: 12,sm:12,md:6,lg:6}}>
                <SwitchAtom text="Pertenece a una comunidad afroColombiana?" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Pertenece a una comunidad afroColombiana?") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Pertenece a una comunidad afroColombiana?")[1]}/>
            </Col>
            </>:
            <></>}
            
            
                
            </>:
            <>
                <Col size={{ xs: 12,sm:12,md:12,lg:12}}>
                    <InputAll size={{lg:"w-50",md:"w-50",xs:"w-100"}} text="Nombre de la Empresa" callBack={callBack} trigger={click} type="text" place="" prev={inputs.inputs.find(({0:nombre})=>nombre==="Nombre de la Empresa") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Nombre de la Empresa")[1]}/>
                </Col>
            </>}
            <Col size={{ xs: 12,sm:12,md:6,lg:6}} d="grid" style={{placeItems:"start"}}>
                <SwitchAtom text="Está bancarizado?" callBack={callBack} banca={banca} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Pertenece a una comunidad afroColombiana?") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Pertenece a una comunidad afroColombiana?")[1]}/>
            </Col> 
            {inputs.banco && inputs.banco ? 
            <Col size={{ xs: 12,sm:12,md:6,lg:6}}>
                <DropdownProjectAtom name="Banco" callBack={callBack} trigger={click} prev={inputs.inputs.find(({0:nombre})=>nombre==="Banco") === undefined ? "":inputs.inputs.find(({0:nombre})=>nombre==="Banco")[1]} menu={getMenu((inputs.drops.find((drop)=>drop.nombre==="Banco") ? inputs.drops.find((drop)=>drop.nombre==="Banco").id:"NO"),inputs.idPais)}/>
            </Col>
            :
            <></>}
                        
            <Div m={{t:"3rem"}} onClick={()=>{
                setClick(true)}}>
                
                <BotonSecundario colorClass="btn-primary" texto="Continuar" tamaño="w-50" font="font-weight-500"></BotonSecundario></Div>
            
            </Div>
        </div>

        </Container>
        </>
    )
}

export default Registrarse
