import {React,useState,useEffect} from 'react'
import {Container,Row,Col,Text,Div,Tag,Icon,Button} from 'atomize'
import {useParams } from 'react-router-dom';
import NavBarLogo from '../Molecules/NavBarLogo'
import Footer from '../Molecules/Footer'
import DropdownProjectAtom from '../Atoms/DropdownProjectAtom'
import InputProjectAtom from '../Atoms/InputProjectAtom'
import SwitchAtom from '../Atoms/SwitchAtom'
import BotonPrimario from '../Atoms/BotonPrimario'
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';


const NuevoProyecto = () => {

    const media = useMediaQuery({ query: `(min-width: 960px)` });
    const mediaQ = useMediaQuery({ query: `(min-width: 1024px)` });
    const pages=["Selecciona el tipo de construcción","Ingresa las características de la construcción","Ingresa la localidad"]
    const {id}=useParams();
    const [state,setState]=useState(1)
    const [categories,setCategories]=useState([])
    const [datos,setDatos]=useState({categoriaId:0,caracteristicas:[],datosProyecto:[]})
    const [enter,setEnter]=useState(false)
    let history = useHistory();

    const handleClick=()=>{
        setEnter(true)
    }
    const callBack=(value,name)=>{
        if(state===2){
            let aux=datos.caracteristicas
            let control=[name,value]
            if(aux.find(({0:name})=>name===control[0])){
                for (let index = 0; index < aux.length; index++) {
                    const element = aux[index];

                    if(element[0]===name){
                        (aux[index])[1]=value
                    }else{
                        continue
                    }
                    
                }
            }else{
                aux.push([name,value])
            }
            setDatos({...datos,caracteristicas:aux})
            if(datos.caracteristicas.length===datos.inputs.length){
                var str=datos.categoriaName+" > "
                datos.caracteristicas.forEach((caracteristica)=>{
                    let aux=caracteristica[1]===true ? "Con": caracteristica[1]===false ? "Sin":""
                    aux=aux==="" ? caracteristica[1]:aux
                    if(caracteristica[0]!=="¿Quiere Construir Por Etapas?")
                        str=str.concat(aux+" "+caracteristica[0]+" > ")
                })
                setDatos({...datos,cadena:str})
                setState(3)
            }  
        }else if(state===3){
            let aux=datos.datosProyecto
            let control=[name,value]
            if(aux.find(({0:name})=>name===control[0])){
                for (let index = 0; index < aux.length; index++) {
                    const element = aux[index];

                    if(element[0]===name){
                        (aux[index])[1]=value
                    }else{
                        continue
                    }
                    
                }
            }else{
                aux.push([name,value])
            }
            setDatos({...datos,datosProyecto:aux})
            if(datos.datosProyecto.length===3){
                history.push(`/choose/${id}`,{data:datos});
            }
        }
        
        setEnter(false)  
    }

    useEffect(()=>{
        //fetch from db
         var  allCategories=[
        {id:0,name:"",icon:""},
        {id:1,name:"Vivienda De Interés Social",icon:"people"},
        {id:2,name:"Vivienda Unifamiliar",icon:"house"},
        {id:3,name:"Vivienda Multifamiliar",icon:"family_restroom"},
        {id:4,name:"Vivienda Con Comercio",icon:"store"},
        {id:5,name:"Rehabilita Tu Vivienda",icon:"construction"},
        {id:6,name:"Comercial",icon:"storefront"},
        {id:7,name:"Industrial",icon:"villa"},
        {id:8,name:"Educacional",icon:"school"},
        {id:9,name:"Social",icon:"holiday_village"},
        {id:10,name:"Deporte",icon:"golf_course"},
        {id:11,name:"Comunal",icon:"festival"},
        {id:12,name:"Muro Perimetral",icon:"fence"},
        {id:13,name:"Muro De Contención",icon:"format_align_justify"}
]
        var aux=[]
        var chunkSize=media ? 3:3
        
        for (var i = 1; i < allCategories.length; i += chunkSize){
            aux.push(allCategories.slice(i, i + chunkSize));
        }
        setCategories(aux)
        
    },[media])
    
    useEffect(()=>{
        //consulta a la bd
        console.log(datos)
        if(datos.categoriaId !== 0){
            var grupos=[]
            var inputs=[{id:"1",nombre:"Tipo",tipo:"CHAR",grupoId:"0"},{id:"2",nombre:"Nmro de Habitaciones",tipo:"NUMB",grupoId:"0"},{id:"3",nombre:"Nmro de Baños Completos",tipo:"NUMB",grupoId:"0"},{id:"4",nombre:"Nmro de Baños sin ducha",tipo:"NUMB",grupoId:"0"},{id:"5",nombre:"Cocina",tipo:"BOOL",grupoId:"0"},{id:"6",nombre:"Comedor",tipo:"BOOL",grupoId:"0"},{id:"7",nombre:"Sala",tipo:"BOOL",grupoId:"0"},{id:"8",nombre:"Lavandero",tipo:"BOOL",grupoId:"0"},{id:"23",nombre:"¿Quiere Construir Por Etapas?",tipo:"BOOL",grupoId:"0"}]
            var opciones=[{valor:"Aislada",caracteristicaId:"1",dominioId:"1"},{valor:"Pareada",caracteristicaId:"1",dominioId:"2"}]
            if(grupos.length===0){
                grupos.push({grupo_id:0,Nombre:""})
            }
            
            setDatos(data=> {return {...data,inputs,grupos,opciones}})
            
        }
    },[datos.categoriaId])
    var counter=0
    return (
        <>
            <NavBarLogo userName={id}/>
            <Container>
            <div class="card pricing h-100 py-4 px-0">
                <nav>
                    <Row h={{ xs: '3rem',sm:'3rem',md:'3rem',lg:"3rem"}} 
                         borderColor="grayBorder" 
                         className="border-bottom px-0 mx-0">
                        
                        <Col size={12} d="grid" style={{placeItems:"center"}}>
                            <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} fontFamily="primary">Paso {state} de 3: {pages[state-1]}</Text>
                        </Col>
                        <Col size={12} className="pb-0 mb-0 px-0" d="flex" align="flex-end">
                            <Div w="100%" h=".3rem" className="pb-0 mb-0" >
                                <Div w={`${state!==3 ? state*33:100}%`} h="100%" bg="blue"></Div>
                            </Div>
                        </Col>
                    
                    </Row>
                </nav>
                <Container p={{x: {xs:"1rem",md:"3rem"}}}>
                    {/*parte de categorias*/}
                    
                    {
                        state===1 ?
                        (
																									<div id="carouselExampleControls" class="carousel slide my-5 px-5" data-ride="carousel">
																										<div class="carousel-inner">
																											{categories.map((group,index)=>{	 
                                                        return(
                                                              mediaQ?(
                                                                   
                                                                  <div class={counter++==0?"carousel-item active":"carousel-item"}>
                                                                    <Row m={{t:"0.5rem",b:"2rem"}} key={index+3}>
                                                                      {group.map((categoria)=>
                                                                        <Col size={4} 
                                                                          key={categoria.id} 
                                                                          onClick={()=>{setDatos({...datos,categoriaId:categoria.id,categoriaName:categoria.name,categoriaIcon:categoria.icon});setState(2)}} 
                                                                          className="newPIcon" 
                                                                          style={{placeItems:"center"}}>
                                                                                  <a class="card card-icons text-center  h-100 lift" href="#!">
                                                                                    <div class="card-body py-5">
                                                                                    
                                                                                        <span key={categoria.id+2} className="material-icons">
                                                                                              {categoria.icon}
                                                                                        </span>
                                                                                    
                                                                                    <Text key={categoria.id+1} 
                                                                                      p="2rem" w="100%" 
                                                                                      textSize={{ xs: 'paragraph',sm:'paragraph',md:'paragraph',lg:"subheader"}} 
                                                                                      textColor="brick" hoverTextColor="white"
                                                                                      fontFamily="primary" d="grid" 
                                                                                      className="card-body-text"
                                                                                      style={{placeItems:"center"}}>
                                                                                              {categoria.name}
                                                                                    </Text>  
                                                                                    
                                                                                    </div>
                                                                                  </a>
                                                                        </Col>
                                                                      )}
                                                                    </Row>       
                                                                  </div>
                                                                 
                                                                )
                                                                :(

                                                                
																																<>
																																	{group.map((categoria)=>
                                                                    <div class={index++==0?"carousel-item active":"carousel-item"}>
                                                                      <Row>
                                                                      <Col size={12} 
                                                                        key={categoria.id} 
                                                                        onClick={()=>{setDatos({...datos,categoriaId:categoria.id,categoriaName:categoria.name,categoriaIcon:categoria.icon});setState(2)}} 
                                                                        className="newPIcon" 
                                                                        style={{placeItems:"center"}}>
                                                                                <a class="card card-icons text-center  h-100 lift" href="#!">
                                                                                  <div class="card-body py-5">
                                                                                  
                                                                                      <span key={categoria.id+2} className="material-icons">
                                                                                            {categoria.icon}
                                                                                      </span>
                                                                                  
                                                                                  <Text key={categoria.id+1} 
                                                                                    p="2rem" w="100%" 
                                                                                    textSize={{ xs: 'paragraph',sm:'paragraph',md:'paragraph',lg:"subheader"}} 
                                                                                    textColor="brick" hoverTextColor="white"
                                                                                    fontFamily="primary" d="grid" 
                                                                                    className="card-body-text"
                                                                                    style={{placeItems:"center"}}>
                                                                                            {categoria.name}
                                                                                  </Text>  
                                                                                  
                                                                                  </div>
                                                                                </a>
                                                                      </Col>
                                                                    

                                                                      </Row>
                                                                    </div>
																																	)}
																																 </>     
                                                                
                                                              )
																														)
																											})}
																									</div>
																									<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
																											<Button
																													h="3rem"
																													w="3rem"
																													bg="white"
																													hoverBg="brick"
																													rounded="circle"
																													m={{ r: "9rem" }}
																													hoverShadow="4"
																											>
																													<Icon name="LeftArrow" size="30px" hoverColor="white" />
																											</Button>
																											<span class="sr-only">Previous</span>
																									</a>
																									<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
																											<Button
																															h="3rem"
																															w="3rem"
																															bg="white"
																															hoverBg="brick"
																															rounded="circle"
																															m={{ l: "9rem" }}
																															hoverShadow="4"
																											>
																													<Icon name="RightArrow" size="30px" hoverColor="white" />
																											</Button>
																											<span class="sr-only">Next</span>
																									</a>
																								</div>
																								): state===2 ?
                        (
                            <Row m={{t:"1.5rem"}}>
                                <Col size={1}/>
                                <Col size={10} d="grid" style={{placeItems:"center"}}>
                                    
                                    <Div d={{xs:"block",md:"flex"}} justify="space-around" align="center" m=".5rem">
                                        <Text textSize={{ xs: 'caption',sm:'paragraph',md:"title",lg:"subheader"}} className="mr-4">
                                            Has seleccionado:  
                                        </Text>
                                        
                                        <Tag
                                        bg={`bg`}
                                        border="1px solid"
                                        borderColor={`bg`}
                                        textColor={`gray`}
                                        textSize={{ xs: 'tiny',sm:'caption',md:'body'}}
                                        rounded="circle"
                                        fontFamily="primary"
                                        
                                        suffix={
                                        <Icon
                                            name="Cross"
                                            size={{ xs: '1rem',sm:'1.5rem',md:'1.5rem'}}
                                            m={{ l: "2rem" }}
                                            cursor="pointer"
                                            onClick={()=>{setDatos({categoriaId:0,caracteristicas:[],datosProyecto:[]});setState(1)}}
                                            color={`gray`}
                                        />}

                                        prefix={
                                            <span className="material-icons mr-2">
                                            {datos.categoriaIcon}
                                            </span>
                                        }

                                        >
                                            {datos.categoriaName}
                                        </Tag>
                                        
                                        
                                        
                                    </Div>
                                    
                                {    datos.grupos ?
                                        (<>
                                        { datos.grupos.map((grupo)=>{
                                            return(<>
                                            <Row key={grupo.grupo_id} m={{t:"1rem",b:"1rem"}}>
                                                    
                                                    <Col size={12}>{grupo.Nombre}</Col>
                                                    
                                                    {
                                                        datos.inputs.map((input)=>{
                                                            if(input.tipo==="CHAR"){
                                                                let menu=datos.opciones.filter(({caracteristicaId})=>caracteristicaId===input.id)
                                                                return <Col p={{b:"1rem"}} key={input.id} size={{xs:12,md:12}} ><DropdownProjectAtom prev={datos.caracteristicas.find(({0:nombre})=>nombre===input.nombre) === undefined ? "":datos.caracteristicas.find(({0:nombre})=>nombre===input.nombre)[1]} key={input.id} trigger={enter} callBack={callBack} name={input.nombre}menu={menu}/></Col>
                                                            }else if(input.tipo==="NUMB"){
                                                                return <Col  p={{b:"1rem"}} key={input.id} size={{xs:12,md:6}} ><InputProjectAtom prev={datos.caracteristicas.find(({0:nombre})=>nombre===input.nombre) === undefined ? "":datos.caracteristicas.find(({0:nombre})=>nombre===input.nombre)[1]} key={input.id} trigger={enter} text={input.nombre} callBack={callBack} type="number" place=""/></Col>
                                                            }else{
                                                                return <Col  p={{b:"1rem"}} key={input.id} size={{xs:12,md:7}} ><SwitchAtom prev={datos.caracteristicas.find(({0:nombre})=>nombre===input.nombre) === undefined ? "":datos.caracteristicas.find(({0:nombre})=>nombre===input.nombre)[1]} key={input.id} trigger={enter} text={input.nombre} callBack={callBack}/></Col>
                                                            }
                                                        })
                                                    }
                                                </Row>
                                                </>)
                                            })}
                                        </>):<></>
                                    }
                                    <Row>
                                        <Col size={1}/>
                                        <Col size={10} p={{b:"2rem"}} onClick={handleClick} d="grid" style={{placeItems:"center"}}>
                                            <BotonPrimario color="brick"texto="Continuar"/>
                                        </Col>
                                        <Col size={1}/>
                                    </Row>
                                    
                                </Col>
                                <Col size={1}/>
                            </Row>
                        ):(
                        <Row m={{y:"1rem"}} p={{x:"1rem",y:"1rem"}}>
                                <Col size={12} >
                                    <Row>
                                        <Col size={12}>
                                            <Tag
                                                    bg={`bg`}
                                                    border="1px solid"
                                                    borderColor={`bg`}
                                                    textColor={`gray`}
                                                    textSize={{ xs: 'tiny',sm:'caption',md:'body'}}
                                                    rounded="circle"
                                                    fontFamily="primary"
                                                    
                                                    suffix={
                                                    <Icon
                                                                    name="Cross"
                                                                    size={{ xs: '1rem',sm:'1.5rem',md:'1.5rem'}}
                                                                    m={{ l: "2rem" }}
                                                                    cursor="pointer"
                                                                    onClick={()=>{setDatos({categoriaId:0,caracteristicas:[],datosProyecto:[]});setState(1)}}
                                                                    color={`gray`}
                                                    />}

                                                    prefix={
                                                                    <span className="material-icons mr-2">
                                                                    {datos.categoriaIcon}
                                                                    </span>
                                                    }

                                                    >
                                                                    Has seleccionado: {datos.categoriaName}
                                                </Tag>
                                        
                                        </Col>    
                                    </Row> 
                                    <Row>
                                        <Col size={12} m={{t:"1rem",l:"1rem"}} p={{t:"1rem",l:"1rem"}}> 
                                            <Text textColor="gray" fontFamily="secondary"textWeight="200"textSize="tiny">{
                                            datos.cadena
                                            }</Text>
                                        </Col>
                                    </Row>
                                    <Row m="2rem">
                                        <Col size={{xs:12,sm:12,md:7,lg:7}}>
                                            <Div
                                                bgImg="https://img.unocero.com/2020/06/google-maps.jpg"
                                                bgSize="contain"
                                                bgPos="center"
                                                h={{xs:"10rem",sm:"10rem",md:"17rem",lg:"17rem"}}
                                            />
                                        </Col>
                                        <Col size={{xs:12,sm:12,md:5,lg:5}}>
                                            <Col  p={{b:"1rem"}} size={12} ><InputProjectAtom prev={datos.datosProyecto.find(({0:nombre})=>nombre==="Direccion Deseada") === undefined ? "":datos.datosProyecto.find(({0:nombre})=>nombre==="Direccion Deseada")[1]} trigger={enter} text="Dirección deseada" callBack={callBack} type="text" place=""/></Col>
                                            <Col  p={{b:"1rem"}} size={12} ><InputProjectAtom prev={datos.datosProyecto.find(({0:nombre})=>nombre==="Persona Contacto") === undefined ? "":datos.datosProyecto.find(({0:nombre})=>nombre==="Persona Contacto")[1]} trigger={enter} text="Persona de contacto" callBack={callBack} type="text" place=""/></Col>
                                            <Col  p={{b:"1rem"}} size={12} ><InputProjectAtom prev={datos.datosProyecto.find(({0:nombre})=>nombre==="Nmro Telefono") === undefined ? "":datos.datosProyecto.find(({0:nombre})=>nombre==="Nmro Telefono")[1]} trigger={enter} text="Número telefónico" callBack={callBack} type="text" place=""/></Col>
                                            <Col size={12} onClick={handleClick} d="grid" style={{placeItems:"center"}}>
                                                <BotonPrimario texto="Ver Resultados" color="brick" tamaño="l"/>
                                            </Col>
                                        </Col>
                                    </Row>
                                    


                                </Col>
                            </Row>)  
                    }
                </Container>
            </div>
        </Container>

        <Footer/>
        </>
    )
}

export default NuevoProyecto
