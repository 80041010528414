import React from 'react'
import {useParams,useHistory } from 'react-router-dom';
import {Container,Row,Col,Div,Text} from 'atomize'
import NavBarLogo from '../Molecules/NavBarLogo'
import Footer from '../Molecules/Footer'
import BotonPrimario from '../Atoms/BotonPrimario'
import builarHappy from '../public/builar-happy.svg'

const Done = () => {
    let history = useHistory();
    const {id}=useParams();
  
    return (
        <>
            <NavBarLogo userName={id}/>
            <Container d="flex" justify="center" align="center" p="2rem" p={{x:{md:"6rem",sm:"1rem"}}}>
                <div class="card pricing h-100 py-2 px-0">
                    <nav>
                        <Row h={{ xs: '3rem',sm:'3rem',md:'3rem',lg:"3rem"}} 
                            borderColor="grayBorder" 
                            className="border-bottom px-0 mx-0">
                            
                            <Col size={12} d="grid" style={{placeItems:"center"}}>
                                <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} className="pb-2" fontFamily="code">¡Todo listo!</Text>
                            </Col>
                            <Col size={12} className="pb-0 mb-0 px-0" d="flex" align="flex-end">
                                <Div w="100%" h=".3rem" className="pb-0 mb-0" >
                                    <Div w="100%" h="100%" bg="blue"></Div>
                                </Div>
                            </Col>
                        
                        </Row>
                    </nav>
                   

                    <Row p={{t:"2.5rem",x:"3rem"}}>
                        <Col size={{xs:12,sm:12,md:4,lg:4}}>
                            <Div
                                m={{y:"2rem"}}
                                bgImg={builarHappy}
                                bgSize="contain"
                                bgPos="center"
                                bgRepeat="no-repeat"
                                h={{xs:"5rem",sm:"5rem",md:"5rem",lg:"12rem"}}
                            />
                        </Col>
                        <Col size={{xs:12,sm:12,md:8,lg:8}}>
                            <Text textSize={{xs:"heading",sm:"display1",md:"display2",lg:"display2"}} textColor="blue" fontFamily="code" m={{b:"1rem"}}>
                                ¡Te contactaremos!
                            </Text>
                            
                            <Text   textSize={{ xs: 'paragraph',sm:'paragraph',md:'subheader',lg:"subheader"}} 
                                    textColor="gray"
                                    p={{r:{xs:"1rem",sm:"1rem",md:"5rem",lg:"10rem"}}}
                                    >
                                Diste el primer paso para <b>iniciar la construcción
                                de tu nueva propiedad</b>,nuestro personal tecnico se pondra 
                                en contacto contigo para asegurar que todo este bien.
                                
                            </Text>
                            
                            <Div d="flex" 
                                 justify={{xs:"center",md:"flex-start"}} 
                                 flexDir={{ xs: 'column', lg: 'row' }}
                                 m={{y:"2rem"}}>
                                <Div m={{y:"1rem"}} onClick={()=>{history.push(`/User/${id}`);}} p={{b:"2rem"}}>
                                    <BotonPrimario texto="Ir al inicio" btnColor="btn-primary"/>
                                </Div>
                            </Div>
                    </Col>
                     </Row>
            
                </div>
            </Container>
            <Footer/>
        </>
    )
}

export default Done
