import React from 'react'
import NavBarLogo from '../Molecules/NavBarLogo'
import BotonPrimario from '../Atoms/BotonPrimario'
import {useParams,useHistory } from 'react-router-dom';
import {Container,Row,Col,Div,Text} from 'atomize'
import builarHappy from '../public/builar-happy.svg'


const Registrado = () => {
    let history = useHistory();
    const {id}=useParams();
    return (
        <>
            <NavBarLogo userName={id}/>
            <Container>
            <div class="card pricing h-100 p-4">
                <Row m={{t:"2rem",b:"1rem"}}>
                        <Col size={{xs:12,sm:12,md:4,lg:4}}>
                            <Div
                                m={{y:"2rem"}}
                                bgImg={builarHappy}
                                bgSize="contain"
                                bgPos="center"
                                bgRepeat="no-repeat"
                                h={{xs:"5rem",sm:"5rem",md:"5rem",lg:"12rem"}}
                            />
                        </Col>
                        <Col size={{xs:12,sm:12,md:8,lg:8}}>
                            <Text textSize={{xs:"display1",sm:"display1",md:"display2",lg:"display2"}} textAlign={{ xs: "center",md:"left", lg: "left" }} textColor="blue" fontFamily="code" m={{b:"1rem"}}>
                                ¡Te damos la bienvenida!
                            </Text>
                            
                            <Text   textSize={{ xs: 'paragraph',sm:'paragraph',md:'subheader',lg:"subheader"}} 
                                    textColor="gray"
                                    p={{r:{xs:"1rem",sm:"1rem",md:"5rem",lg:"15rem"}}}
                                    textAlign={{ xs: "center",md:"left", lg: "left" }}
                                    >
                                ¡Gracias por registrarte! En este momento estás a
                                pocos pasos de materializar tu vivienda, impulsar tu
                                emprendimiento y construir tus sueños.
                                <br/>
                                <br/>
                                <b>¡Puedes empezar ya mismo!</b>
                                
                            </Text>
                            
                            <Div d="flex" 
                                 justify={{xs:"center",sm:"flex-start",md:"flex-start"}} 
                                 align={{xs:"center",sm:"left",md:"flex-start",lg:"center"}}
                                 flexDir={{ xs: 'column', lg: 'row' }}
                                 m={{y:"2rem"}}>
                                <Div onClick={()=>{history.push(`/startProject/${id}`);}} 
                                     p={{b:"2rem"}}
                                     m={{r:"1rem"}}
                                >
                                    <BotonPrimario texto="Iniciar un proyecto" btnColor="btn-primary"/>
                                </Div>
                                <Div m={{y:"1rem"}} onClick={()=>{history.push(`/User/${id}`);}} p={{b:"2rem"}}>
                                    <BotonPrimario texto="Ir al inicio" btnColor="btn-secondary"/>
                                </Div>
                            </Div>
                    </Col>
                </Row>
            </div>  
            </Container>
        </>
    )
}

export default Registrado
