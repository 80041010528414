import {React} from 'react'
import {Container,Row,Col,Div,Text, Icon} from 'atomize'
import NavBarLogo from '../Molecules/NavBarLogo'
import NavBarUser from '../Molecules/NavBarUser'
import {useParams } from 'react-router-dom';
import Footer from '../Molecules/Footer'
import { Link } from 'react-router-dom';
import MTag from '../Atoms/_Tags';

const UserHomePage = () => {
    const {id}=useParams();
    const proyectos=[
        {
            nombre:"Lomas del Caroní",
            total:10,
            completado:2,
            id:"proyecto-123",
            etapas:[{
                estado:"Completado",
                costo:"250 USD",
                id:1,
                desc:"Adquisición de bloques para construcción"
            },
        {
                estado:"En espera",
                costo:"567.56 USD",
                id:2,
                desc:"Adquisición de bloques para construcción"
            }]
        },
        {
            nombre:"Lomas del Caroní",
            total:14,
            completado:6,
            id:"proyecto-456",
            etapas:[{
                estado:"Completado",
                costo:"250 USD",
                id:3,
                desc:"Adquisición de bloques para construcción"
            },
        {
                estado:"en espera",
                costo:"567.56 USD",
                id:4,
                desc:"Adquisición de bloques para construcción"
            }]
        }
    ]
    const objectAxios={
        Nombre:"Arturo Lares",
        DNI:"V-8935243",
        Teléfono:"0414-821345",
        Email:"arturolares@gmail.com",
        Ubicación:"Nueva Esparta, Venezuela",
        saldo:"32535 USD"
    }
    const objectAxiosP=[{
        idMovimiento:1,
        Descripcion:"Depósito",
        Cantidad:"120 USD",
        estado:"aprobado"
    },
{
        idMovimiento:43,
        NombreProyecto:"Lomas del Carolina",
        Descripcion:"Etapa",
        estado:"aprobado"
    },{
idMovimiento:423,
        NombreProyecto:"Lomas del Carolina",
        Descripcion:"Etapa",
        estado:"no aprobado"
    }]
    const config={
         border:"1px solid",
         borderColor:"lightgray",
         shadow:"2"
    }
    return (
        <>
            <NavBarLogo userName={id}/>
            <Container>
                <div class="card">

                    <NavBarUser id={id} selected={1}/>
                    <Row m={{t:"1rem",b:"2rem",x:{md:"5rem",sm:"1rem",xs:"1rem"}}}>
                        
                        <Col size={{xs:12,sm:12,md:12,lg:5}}>
                            <Div>
                                <Text 
                                textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}}
                                textColor="brick"
                                fontFamily="code"
                                >Perfil</Text>
                                <Div className="card" {...config} p="1rem" >
                                    {
                                        Object.keys(objectAxios).map((attr,i)=>{
                                            if(attr==="saldo"){
                                                return <></>
                                            }
                                            return (
                                                <Row key={i} className="item">
                                                    <Col size={4}>
                                                        <Text textSize={{ xs: 'paragraph',sm:'paragraph',md:'paragraph',lg:"paragraph"}} fontFamily="primary">
                                                            {`${attr}:`}
                                                        </Text>
                                                    </Col>
                                                    <Col size={8}>
                                                       {`${objectAxios[attr]}`} 
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </Div>
                            </Div>
                            <Div>
                                <Text 
                                textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}}
                                textColor="brick"
                                fontFamily="code"
                                >Billetera</Text>
                                <Div className="card" {...config} p={{sm:"0rem",md:"1rem"}}>
                                    <Row m={{t:"0.5rem"}}>
                                        <Col size={{xs:12,sm:12,md:12,lg:8}}>
                                            <Text textSize="paragraph" fontFamily="code">Fondos Disponibles: {objectAxios.saldo}</Text>
                                        </Col>
                                        <Col size={{xs:12,sm:12,md:12,lg:4}}>
                                            <Text textSize="paragraph" textColor="blue" fontFamily="code" cursor="pointer">Ir a billetera</Text>

                                        </Col>
                                    </Row>
                                    <Row m={{b:"0.5rem"}}>
                                        <Col size={12}>
                                            <Text textSize={{ xs: 'paragraph',sm:'paragraph',md:'subheader',lg:"subheader"}} fontFamily="primary">Ultimos Movimientos:</Text>
                                        </Col>
                                    </Row>
                                    
                                    {
                                        objectAxiosP.map((movimiento)=>{
                                            return(
                                            
                                                
                                                    <Row key={movimiento.idMovimiento} m={{b:".5rem"}}>
                                                        <Col d="grid" style={{placeItems:"center"}} size={12}>
                                                            <MTag text={`${movimiento.Descripcion} #${movimiento.idMovimiento} ${movimiento.NombreProyecto ? "proyecto "+movimiento.NombreProyecto:movimiento.Cantidad}`}
                                                                  color={movimiento.estado==="aprobado" ? "greenBG":"brickBt"}
                                                                  textColor={movimiento.estado==="aprobado" ? "green":"brick"}
                                                                  borderColor="none"
                                                                  iconr="RightArrow"
                                                            ></MTag>
                                                        </Col>
                                                    </Row>
                    
                                            
                                            )

                                            
                                        })
                                    }
                                </Div>
                            </Div>
                        </Col>
                        <Col size={{xs:12,sm:12,md:12,lg:7}}>
                            <Div m={{b:"1.5rem"}}>
                                <Text 
                                textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}}
                                textColor="brick"
                                fontFamily="code"
                                >Proyectos</Text>
                                <Div className="accordion accordion-faq" id="Projects" border="1px solid" borderColor="lightgray" shadow="2">
                                    {proyectos.map((proyecto)=>{
                                        return (
                                            <Div className="card accordion-faq-item">
                                                <a class="card-header collapsed" id={proyecto.id} data-toggle="collapse" data-target={'#Collapse-'+proyecto.id} aria-expanded="false" aria-controls={'Collapse-'+proyecto.id} href="javascript:void(0);">
                                                    <Div className="accordion-faq-item-heading">
                                                        <Row p={{t:"0.5rem"}}>
                                                            <Col size={12}>
                                                                <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="code" textColor="blue">{proyecto.nombre}</Text>
                                                            </Col>
                                                        
                                                            <Col size={12}>
                                                                <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary">{proyecto.completado} de {proyecto.total} etapas completadas</Text>
                                                            </Col>
                                                        
                                                        </Row>
                                                        <Icon className="feather feather-chevron-down accordion-faq-item-heading-arrow" name="DownArrow" size="24px"></Icon>    
                                                    </Div>
                                                </a>
                                                <Div className="collapse" id={'Collapse-'+proyecto.id} aria-labelledby={"authHeading"+proyecto.id} data-parent="#Projects">
                                                    <Div className="card-body">
                                                        {
                                                            proyecto.etapas.map((etapa) => (
                                                                <Row key={etapa.id} className="item">
                                                                    <Col size={8} >
                                                                        <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="code" textColor={etapa.estado==="Completado" ? "green":"red"}>{etapa.estado}</Text>
                                                                        <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary" >{etapa.desc}</Text>
                                                                    </Col>
                                                                    <Col size={4} >
                                                                        <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary">Costo de la etapa:</Text>
                                                                        <Text   textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="code">{etapa.costo}</Text>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }  
                                                    </Div>
                                                </Div>
                                            </Div>
                                        )
                                    })}
                                    <Row>
                                        <Col d="grid" style={{placeItems:"center"}} size={12}>
                                            <Text  textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}} fontFamily="primary">
                                                No tienes más proyectos. <Link to={`/startProject/${id}`}>Iniciar un proyecto</Link>
                                            </Text>
                                        </Col>
                                    </Row>
                                </Div>
                            </Div>
                        </Col>
                        
                    </Row>

                </div>
            </Container>
            <Footer/>
            
        </>
    )
}

export default UserHomePage
