import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './public/Fonts/Gelion/Gelion-Bold.ttf'
import './public/Fonts/Gelion/Gelion-Light.ttf'
import './public/Fonts/Gelion/Gelion-Regular.ttf'
import './config.css'
import './styles.css'
import './css/_input.scss'
import './css/_buttons.scss'
import './css/_cards.scss'
import './css/_scrolls.scss'
import './css/_links.scss'





import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { ThemeProvider, StyleReset } from 'atomize';

const debug =
  process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

// 1. Create a client engine instance
const engine = new Styletron();

// 2. Provide the engine to the app
// debug engine needs inlined source maps

//1366x768

const theme = {
  fontFamily: {
    primary:"GelionRegular",
    secondary:"GelionLight",
    code:"GelionBold"
  },
  colors: {
    brick: "#EE5340",
    brickBt:"#FFDDD9",
    redBG:"#FFDDD9",
    red:"#FF6666",
    redText:"#CC2929",
    blue: "#171C8F",
    gray: "#7A7A7A",
    grayBorder:"#B9B9B9",
    lightgray:"#e0e0e0",
    green: "#16C946",
    greenBG:"#80FFA2",
    greenBGText:"#13B03D",
    greenText:"#13B03D",
    pink: "#F63F3F",
    yellow: "#FFC53D",
    blackText:"#23252A",
    blueText:"#0A1F44",
    bg:"#F5F5F5",
    graypb:"#D5D5D5"
  },
  grid: {
    gutterWidth: "16px"
  }
};

ReactDOM.render(
  <React.StrictMode>
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <ThemeProvider theme={theme}>
        <StyleReset />
        <App />
      </ThemeProvider>
    </StyletronProvider>
  </React.StrictMode>,
  
  document.getElementById('root')
);

