import {React,useState,useEffect} from 'react'
import { Div,Row,Col,Icon,Text,Anchor, Container} from "atomize";
import BotonPrimario from '../Atoms/BotonPrimario'


const DropdownEtapa = ({etapa,recursos,user}) => {
    const [rec,setRec]=useState([])
    console.log(rec)
    useEffect(() => {
        console.log(recursos)
        let aux=recursos.filter((recurso=>recurso.nmro_etapa===etapa.nmro_etapa))
        console.log(aux)
        
        setRec(aux)
    }, [])
    const menuList = (
  <Div>
    <Row >
        <Col size={{ xs: 12,sm:12,md:12,lg:5}} p="0.2rem">

            <Text textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}} fontFamily="code" m={{b:"0.8rem"}}>Descripción</Text>
            <Div className="card" border="1px solid" borderColor="lightgray" p="1rem"  m={{b:"1.2rem"}}>
                <Text textSize="paragraph" fontFamily="primary">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.</Text>
            </Div>

            <Text textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}} fontFamily="code"  m={{b:"0.8rem"}}>Presupuesto</Text>
            <Div className="card" border="1px solid" borderColor="lightgray" p="1rem">
                <Div d="flex" align="flex-end" justify="center" flexWrap="wrap">
                    <Text textSize={{ xs: 'heading',sm:'display1',md:'display2',lg:"display2"}} textColor="blue"fontFamily="code" m={{b:"0.8rem"}} >{user.saldo}/{etapa.costo_etapa}</Text>
                </Div>
                <BotonPrimario texto="Depositar" btnColor="btn-primary w-100" color="brick"/>

            </Div>
            
        </Col>
        <Col size={{ xs: 12,sm:12,md:12,lg:7}} p="1rem">
            <Text textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}} fontFamily="code" m={{b:"0.8rem"}}>Documentación:</Text>
            <Div className="card" border="1px solid" borderColor="lightgray" p="1rem">
                {
                    rec.length===0 ? <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader'}}  textColor="blue" fontFamily="code">Actualmente no posee recursos</Text>
                    :
                    rec.map((recurso,index)=>{
                    return(
                        <Row key={index}>
                            <Col size={12} d="flex" align="center" justify="space-between" flexWrap="wrap" className="item">
                                <Text textSize={{ xs: 'caption',sm:'paragraph',md:'paragraph'}}  fontFamily="primary">{recurso.nombre}</Text>
                                <Anchor href={recurso.url_video} target="_blank"><Text textSize={{ xs: 'caption',sm:'paragraph',md:'paragraph'}}  fontFamily="code">{recurso.tipo_recurso}</Text></Anchor>
                            </Col>
                        </Row>
                    )}
                    )
                }
            </Div>
        </Col>
    </Row>
  </Div>
);

    return (
    <Div className="card accordion-faq-item w-100">
        <a class="card-header collapsed" id={etapa.id} data-toggle="collapse" data-target={'#Collapse-'+etapa.id} aria-expanded="false" aria-controls={'Collapse-'+etapa.id} href="javascript:void(0);">
            <Div className="accordion-faq-item-heading">
                <Row p={{t:"0.5rem"}}>
                    <Col size={{ xs: 5,sm:5,md:6,lg:6}}>
                        <Text textSize={{ xs: 'tiny',sm:'caption',md:'body'}}  fontFamily="code" textAlign="start">{etapa.estado}</Text>
                        <Text d="grid" style={{placeItems:"center"}} textSize={{ xs: 'tiny',sm:'caption',md:'body'}} fontFamily="code">{etapa.nmro_etapa}. {etapa.titulo}</Text>
                    </Col>
                    <Col d="grid" style={{placeItems:"center"}}
                    size={{ xs: 4,sm:4,md:4,lg:4}}>
                        <Text textSize={{ xs: 'tiny',sm:'caption',md:'caption'}} fontFamily="code" textAlign="start">Costo de la etapa:</Text>
                        <Text textSize={{ xs: 'tiny',sm:'caption',md:'body'}} fontFamily="code" textAlign="start">{etapa.costo_etapa}</Text>
                    </Col>
                    <Col d="grid" style={{placeItems:"center"}}
                    size={{ xs:3,sm:3,md:2,lg:2}}>
                        <Text textSize={{ xs: 'tiny',sm:'caption',md:'caption'}}fontFamily="code" textAlign="start">Documentos:</Text>
                        <Text textSize={{ xs: 'tiny',sm:'caption',md:'body'}} fontFamily="code" textAlign="start">{rec.length>0 ? "Si":"No"}</Text>
                    </Col>
                </Row>
                <Icon className="feather feather-chevron-down accordion-faq-item-heading-arrow" name="DownArrow" size="24px"></Icon>    
            </Div>
        </a>
        <Div className="collapse" id={'Collapse-'+etapa.id} aria-labelledby={"authHeading"+etapa.id} data-parent="#Stages">
        <Div className="card-body">
            <Container>
            {menuList}  
            </Container>
        </Div>
    </Div>
    </Div>

      
    )
}

export default DropdownEtapa
