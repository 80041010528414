import { React,useState,useEffect } from 'react'
import NavBarLogo from '../Molecules/NavBarLogo'
import NavBarUser from '../Molecules/NavBarUser'
import Footer from '../Molecules/Footer'
import DropdownEtapas from '../Molecules/DropdownEtapa'
import {Container,Row,Col,Div,Text,Tag,Icon,Image} from 'atomize'
import {useParams,useHistory } from 'react-router-dom';



const ProjectDetails = () => {
    let history = useHistory();
    const {id}=useParams();
    const [data,setData]=useState({init:true,proyecto:{},user:{},etapas:[],recursos:[],imagenes:[],aportes:[]})
    const pId="1"
    const [index,setIndex]=useState(0)

    useEffect(() => {
        //consulta a la bd
        let proyecto={
            proyecto_id:pId,
            URL_etapas:"http://composicion.aq.upm.es/Master/Modulo%20B/Maure/3.1._Modelos,%20tipos%20y%20tipologia.pdf",
            nmro_etapas:"15",
            etapas_listas:"1",
            modelo_id:"2",
            fecha_inicio:"27/05/2021",
            fecha_finalizacion:"12/11/2023",
            fecha_estimada_fin:"01/12/2023",
            Nombre:"Casa Greek Hills",
            descripcion:"Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.",
            cadenaCaracteristicas:"Vivienda de interés social > aislada > 2 habitaciones > 2 baños > 2 niveles",
            nivel1:"Nueva Esparta",
            nivel2:"Maneiro",
            nivel3:"Agua de Vaca",
            pais:"Venezuela",
            inv:"250$"
        }
        let user={
            id:id,
            saldo:"100$"
        }
        let etapas=[
            {
                id:"1",
                estado:"Finalizada",
                proyecto_id:pId,
                fecha_inicio:"30/05/2021",
                fecha_fin:"15/06/2021",
                costo_etapa:"250$",
                titulo:"Adquisición de bloques para construcción",
                nmro_etapa:"1"
            },
            {
                id:"2",
                estado:"En obra",
                proyecto_id:pId,
                fecha_inicio:"",
                fecha_fin:"",
                titulo:"Adquisición de bloques para construcción",
                costo_etapa:"750$",
                nmro_etapa:"2"
            },
            {
                id:"3",
                estado:"Por pagar",
                proyecto_id:pId,
                fecha_inicio:"",
                titulo:"Adquisición de bloques para construcción",
                fecha_fin:"",
                costo_etapa:"500$",
                nmro_etapa:"3"
            }

        ]
        let recursos=[
            {
                url_video:"http://composicion.aq.upm.es/Master/Modulo%20B/Maure/3.1._Modelos,%20tipos%20y%20tipologia.pdf",
                tipo_recurso:"Archivo",
                modelo_id:"2",
                nmro_etapa:"1",
                nombre:"Planos de etapa"
            },
            {
                url_video:"http://composicion.aq.upm.es/Master/Modulo%20B/Maure/3.1._Modelos,%20tipos%20y%20tipologia.pdf",
                tipo_recurso:"Archivo",
                modelo_id:"2",
                nmro_etapa:"1",
                nombre:"Materiales"
            },
            {
                url_video:"http://composicion.aq.upm.es/Master/Modulo%20B/Maure/3.1._Modelos,%20tipos%20y%20tipologia.pdf",
                tipo_recurso:"Video",
                modelo_id:"2",
                nmro_etapa:"1",
                nombre:"Paso a paso"
            },
            {
                url_video:"http://composicion.aq.upm.es/Master/Modulo%20B/Maure/3.1._Modelos,%20tipos%20y%20tipologia.pdf",
                tipo_recurso:"Archivo",
                modelo_id:"2",
                nmro_etapa:"2",
                nombre:"Planos de etapa"
            },
            {
                url_video:"http://composicion.aq.upm.es/Master/Modulo%20B/Maure/3.1._Modelos,%20tipos%20y%20tipologia.pdf",
                tipo_recurso:"Archivo",
                modelo_id:"2",
                nmro_etapa:"2",
                nombre:"Materiales"
            },
            {
                url_video:"http://composicion.aq.upm.es/Master/Modulo%20B/Maure/3.1._Modelos,%20tipos%20y%20tipologia.pdf",
                tipo_recurso:"Video",
                modelo_id:"2",
                nmro_etapa:"2",
                nombre:"Paso a paso"
            },
            {
                url_video:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzVLzZ3U-EOP_PDpCCkMyMMhBD00ILL9XzYdvetqrMtM1oBcFEY9J2z3SFrHVGDRgzfI4&usqp=CAU",
                tipo_recurso:"Imagen",
                modelo_id:"2",
                nmro_etapa:"0",
                nombre:"Vista frontal"
            },
            {
                url_video:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0Cns0R3PUAcrHXKfQEAKpeinQDA6x3zfL6lWRvKB38H5cFix5HNnu34ob7-q77xE5kJ4&usqp=CAU",
                tipo_recurso:"Imagen",
                modelo_id:"2",
                nmro_etapa:"0",
                nombre:"Espaciado"
            },
            {
                url_video:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb7RmGd2z5G6aJk7l3_bj4ZDx2eYzusuftiP3UQ8CT0PKcezyo5OQEfEV2l_gJ0BYIK2w&usqp=CAU",
                tipo_recurso:"Imagen",
                modelo_id:"2",
                nmro_etapa:"0",
                nombre:"No se"
            },
        ]
        let aportes=[{usuarioId:"1",nombre:"Peter A. David"},{usuarioId:"1",nombre:"Sidor"},{usuarioId:"1",nombre:"Calos A. Jiménez"}]
        let imagenes=recursos.filter((recurso)=>recurso.tipo_recurso==="Imagen" && recurso.nmro_etapa==="0")
        console.log(recursos)

        setData((data)=>{return{...data,proyecto,user,recursos,etapas,etapasAux:etapas,imagenes,aportes}})

    }, [data.init])

    const getSearch=(value)=>{
        var filtered


            filtered= data.etapasAux.filter(etapa => {
                return (etapa.estado.toLowerCase().includes(value.toLowerCase()))
            })
        
        
        setData((data)=>{return{...data,etapas:filtered}})
        
    }
    
    return (
        <>
            <NavBarLogo userName={id}/>
            <Container>
                <Div className="card">
                    <NavBarUser id={id}  selected={2}/>
                    <Row  m={{t:"2rem",b:"1rem"}}>
                    <Container p={{x:{md:"6rem",sm:"1rem"}, y:{md:"1rem",sm:"1rem"}}}>
                        <Col size={12}>
                            <Row>
                                <Col size={{ xs: 12,sm:12,md:12,lg:6}}>
                                    <div id="Carousel-Img" class="carousel slide" data-ride="carousel" data-interval="5000">
                                    <div class="carousel-inner">
                                        {data.imagenes.map(

                                            (imagen,index)=>{
                                                return(
                                                    <Div className={index++==0?"carousel-item active":"carousel-item"}>
                                                       <Div bgImg={imagen.url_video} bgSize="cover" h="20rem" />
                                                    </Div>
                                                )
                                            }
                                        )
                                        
                                        }
                                    </div>
                                    <a class="carousel-control-prev" href="#Carousel-Img" role="button" data-slide="prev">
                                        <Icon
                                            name="LeftArrow"
                                            size="3rem"
                                            cursor="pointer"
                                            color="white"
                                        />
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#Carousel-Img" role="button" data-slide="next">
                                        <Icon
                                            name="RightArrow"
                                            size="3rem"
                                            cursor="pointer"
                                            color="white"
                                        />
                                        <span class="sr-only">Next</span>
                                    </a>
                                    </div>
                                </Col>
                                <Col size={{ xs: 12,sm:12,md:12,lg:6}}>
                                    <Text textColor="brick" textWeight="600" textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}} m={{b:".8rem"}}>{data.proyecto.Nombre}</Text>
                                    <Text fontFamily="primary" textSize="tiny" className="border-bottom border-top">{data.proyecto.cadenaCaracteristicas}</Text>
                                    <Text m={{b:"2rem",t:"2rem"}}textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}}>{data.proyecto.descripcion}</Text>
                                    <Row>
                                        <Col size={12} d="flex" justify="space-between" flexWrap="wrap" textSize={{ xs: 'tiny',sm:'body',md:'paragraph',lg:"paragraph"}} className="item" >
                                            <Text>Localidad:</Text>
                                            <Text>{data.proyecto.nivel3}, {data.proyecto.nivel1}, {data.proyecto.pais}</Text>
                                        </Col>
                                        
                                        <Col size={12} d="flex" justify="space-between" flexWrap="wrap" textSize={{ xs: 'tiny',sm:'body',md:'paragraph',lg:"paragraph"}} className="item">
                                            <Text>Inversión Realizada:</Text>
                                            <Text>{data.proyecto.inv}</Text>
                                        </Col>
                                        
                                        <Col size={12} d="flex" justify="space-between" flexWrap="wrap" textSize={{ xs: 'tiny',sm:'body',md:'paragraph',lg:"paragraph"}} className="item">
                                            <Text>Etapas concretadas:</Text>
                                            <Text>{data.proyecto.etapas_listas} de {data.proyecto.nmro_etapas} etapas</Text>
                                        </Col>
                                        
                                        <Col size={12} d="flex" justify="space-between" flexWrap="wrap" textSize={{ xs: 'tiny',sm:'body',md:'paragraph',lg:"paragraph"}} className="item">
                                            <Text>Inversión restante:</Text>
                                            <Text>1250$</Text>
                                        </Col>
                                        
                                    </Row>
                                </Col>
                            </Row>
                            {
                                //Contribuyentes
                            }
                            <Row m={{l:"0rem",b:"2rem",t:"2rem"}}>
                                <Col size={12}>
                                    <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader'}} textWeight="600">Contribuyentes</Text>
                                </Col>
                                <Col size={12}>
                                    <Row>
                                    {
                                        data.aportes.map((aporte)=>{
                                            return(<Col size={{ xs: 2,sm:2,md:1,lg:1}}>
                                                <Row d="grid" style={{placeItems:"center"}}>
                                                    <Col><Icon color="gray" name="UserCircle" size={{ xs: '2rem',sm:'2rem',md:'3rem'}} /></Col>
                                                    <Col><Text textSize={{ xs: 'tiny',sm:'caption',md:'body'}} textAlign="center" fontFamily="secondary" textWeight="100">{aporte.nombre}</Text></Col>
                                                </Row>
                                            </Col>)
                                        })
                                    }
                                    </Row>
                                </Col>
                            </Row>
                            {
                                //Etapas
                            }
                        <Row>
                            <Col size={12}>
                                <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader'}} textWeight="600">Etapas</Text>
                            </Col>
                        </Row>
                        <Row>
                        <Col size={12}>
                            <Div d="flex" flexWrap="wrap">
                        <Tag
                            cursor="pointer"
                            onClick={()=>{
                                getSearch("")
                            }}
                                textSize={{ xs: 'tiny',sm:'caption',md:'caption',lg:"paragraph"}}
                                bg="redBG"
                                border="1px solid"
                                borderColor="brick"
                                textColor="red"
                                hoverBg="brick"
                                m={{ r: "0.5rem", b: "0.5rem" }}
                            >
                                Todos
                            </Tag>
                        {[{name:"Finalizada"},{name:"En obra"},{name:"Por pagar"}].map(
                            (estado,index) => (
                            <Tag
                            textSize={{ xs: 'tiny',sm:'caption',md:'caption',lg:"paragraph"}}
                            cursor="pointer"
                            key={index}
                            onClick={()=>{
                                getSearch(estado.name)
                            }}
                                bg="redBG"
                                border="1px solid"
                                borderColor="brick"
                                textColor="red"
                                hoverBg="brick"
                                m={{ r: "0.5rem", b: "0.5rem" }}
                            >
                                {estado.name}
                            </Tag>
                            )
                        )}
                    </Div>
                        </Col>
                    </Row>
                    {
                        //parte de etapas
                    }
                        <Row m={{b:"3rem"}}>
                            <Div className="accordion accordion-faq w-100" id="Stages" border="1px solid" borderColor="lightgray" shadow="2">

                            {
                                console.log(data.etapas),
                                data.etapas.map((etapa,index)=>{
                                    return(
                                        <DropdownEtapas etapa={etapa} recursos={data.recursos} user={data.user}/>
                                    )
                                })
                            }
                            </Div>
                        </Row>
                        </Col>
                        
                    </Container>
                    
                </Row>
                </Div>
            </Container>
            <Footer/>
        </>
    )
}

export default ProjectDetails

