import {React,useState,useEffect} from 'react'
import {Switch,Label} from 'atomize'

const SwitchAtom = ({text,callBack,trigger,prev,banca}) => {
    const [selectedSwitchValue,setSelectedSwitchValue]=useState(false)
    useEffect(() => {
        if(trigger && selectedSwitchValue!==prev){
            callBack(selectedSwitchValue,text)
        }
    }, [trigger,selectedSwitchValue,text,callBack,prev])
    return (
        <Label
              onClick={() =>{
                if(banca){
                  banca(!selectedSwitchValue)
                }
                setSelectedSwitchValue(!selectedSwitchValue)
              }}
              align="center"
              textWeight="600"
              m={{ b: "1rem" }}
              textSize={{ xs: 'tiny',sm:'paragraph',md:'subheader',lg:"subheader"}}
              >
              <Switch
                checked={selectedSwitchValue}
                inactiveColor="gray"
                activeColor="brick"
                activeShadow="5"
              />
            {text}
        </Label>
    )
}

export default SwitchAtom
