import {React,useState,useEffect,useMemo} from 'react'
import {Container,Row,Col,Tag,Div} from 'atomize'
import NavBarLogo from '../Molecules/NavBarLogo'
import {useParams } from 'react-router-dom';
import NavBarUser from '../Molecules/NavBarUser'
import NavBarProjects from '../Molecules/NavBarProjects'
import Footer from '../Molecules/Footer'
import ShowProjects from '../Molecules/ShowProjects'

const UserProjectsPage = () => {

    const data=useMemo(() =>[{
        url:"",
        nombre:"Lomas del Caroni",
        id:"1",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Unifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"12",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Unifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"122",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Multifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"12222",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Unifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"13",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Unifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"14",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Unifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"15",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Unifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"16",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Multifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"17",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Multifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    },{
        url:"",
        nombre:"Lomas del Caroni",
        id:"18",
        completado:2,
        total:12,
        primerNivel:"Estado Bolivar",
        segundoNivel:"Puerto Ordaz",
        categoria:"Vivienda Unifamiliar",
        descripcion:"Exclusivo apartamento duplex en Pampatar sector playa moreno Urb. Playas del ángel, Res. La Roca Loft Margarita. 151m2 dúplex distribuido en: 82, 40m2",
        fechaInicio:"12/08/2021"
    }], []) 
    
    const categorias=[{name:"Vivienda de Interes Social",id:1},{name:"Vivienda Multifamiliar",id:2},{name:"Vivienda Unifamiliar",id:3}]

    const {id}=useParams();
    const [list,setList]=useState([])
    const [defaultList,setDefaultList]=useState([])

    useEffect(()=>{
        setList(data)
        setDefaultList(data)
    },[data])

    const getSearch=(value)=>{
        var filtered

        if(categorias.find((categoria)=>categoria.name===value)){
            filtered= defaultList.filter(project => {
                return (project.categoria.toLowerCase().includes(value.toLowerCase() ))
            })
        }else{
            filtered= defaultList.filter(project => {
                return (project.nombre.toLowerCase().includes(value.toLowerCase() ))
            })
        }
        
        setList(filtered)
    }
    
    return (
        <>
            <NavBarLogo userName={id}/>
            <Container>
                <Div className="card">
                    <NavBarUser id={id} selected={2}/>
                    
                    <Div m={{y:"0.5rem"}}>
                        <NavBarProjects Back={getSearch}/>
                    </Div>
                    

                    <Row>
                        <Col size={1}/>
                        <Col size={10}>
                            <Div d="flex" flexWrap="wrap" m={{b:"1rem"}} >
                                <Tag
                                    cursor="pointer"
                                    onClick={()=>{
                                        getSearch("")
                                    }}
                                        textSize={{ xs: 'tiny',sm:'caption',md:'caption',lg:"paragraph"}}
                                        bg="redBG"
                                        border="1px solid"
                                        borderColor="brick"
                                        textColor="red"
                                        hoverBg="brick"
                                        m={{ r: "0.5rem", b: "0.5rem" }}
                                    >
                                        Todos
                                    </Tag>

                                {categorias.map(
                                    categoria => (
                                    <Tag
                                    textSize={{ xs: 'tiny',sm:'caption',md:'caption',lg:"paragraph"}}
                                    cursor="pointer"
                                    key={categoria.id}
                                    onClick={()=>{
                                        getSearch(categoria.name)
                                    }}
                                        bg="redBG"
                                        border="1px solid"
                                        borderColor="brick"
                                        textColor="red"
                                        hoverBg="brick"
                                        m={{ r: "0.5rem", b: "0.5rem" }}
                                    >
                                        {categoria.name}
                                    </Tag>
                                    )
                                )}
                            </Div>
                        </Col>
                        <Col size={1}/>
                    </Row>
                    <Row>
                        
                        <Container p={{x:{md:"6rem",xs:"1rem"}}}>
                            <ShowProjects data={list}/>
                        </Container>
                        
                    </Row>
    

                </Div>
            </Container>
            <Footer/>
        </>
    )
}

export default UserProjectsPage
