import {React,useState,useEffect} from 'react'
import {Container,Row,Col,Div,Text} from 'atomize'
import { useLocation,useParams,useHistory } from 'react-router-dom';
import NavBarLogo from '../Molecules/NavBarLogo'
import Footer from '../Molecules/Footer'
import BotonPrimario from '../Atoms/BotonPrimario'


const Compare = () => {
    let history = useHistory();
    const {id}=useParams();
    const location = useLocation();
    const [data,setData]=useState({selectedIds:location.state.selectedIds,data:[]})
    const handleClick=(idSelected)=>{
        history.push(`/done/${id}`);
    }
    const s="Vivienda de interés social > aislada > 2 habitaciones > 2 baños > 2 niveles"

    useEffect(() => {
        let d=`Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum
            dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.
            Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum
            dolor sit amet.`
        let aux=[{img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:1,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:2,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2},
            {img:["https://geocax.com/wp-content/uploads/2018/09/plano-casa-9.jpg","https://geocax.com/wp-content/uploads/2018/09/plano-casa-1.jpg"],modeloId:3,desc:d,name:"Casa Greek hills",costoPromedio:"125$",costoTotal:"15.000$",nmroEtapas:15,habitaciones:2,tipo:"Aislada",baños:2}]
        setData((data)=>{return {...data,data:aux}})
    }, [])
    console.log(data)
    return (
        <>
            <NavBarLogo userName={id}/>
            <Container>
                <div class="card pricing h-100 py-4 px-0">
                    <nav>
                        <Row h={{ xs: '3rem',sm:'3rem',md:'3rem',lg:"3rem"}} 
                            borderColor="grayBorder" 
                            className="border-bottom px-0 mx-0">
                            
                            <Col size={12} d="grid" style={{placeItems:"center"}}>
                                <Text textSize={{ xs: 'caption',sm:'paragraph',md:'subheader',lg:"subheader"}} className="pb-2" fontFamily="primary">Comparativa: Elige el que más se adecúe a tus necesidades</Text>
                            </Col>
                            <Col size={12} className="pb-0 mb-0 px-0" d="flex" align="flex-end">
                                <Div w="100%" h=".3rem" className="pb-0 mb-0" >
                                    <Div w="100%" h="100%" bg="blue"></Div>
                                </Div>
                            </Col>
                        
                        </Row>
                    </nav>
                    <Container>
                    <Row m={{b:"2rem",t:"2rem"}}>
                        {data.data.map((modelo)=>(
                            
                            <Col size={{ xs: 12,sm:12,md:4,lg:4}}>
                                <Div
                                    cursor="pointer" 
                                    className="card"
                                >
                                    <img class="card-img-top" src="https://cf.bstatic.com/images/hotel/max500/211/211169617.jpg" alt="..."></img>
                                    <Div className="card-body" p="1.5rem">
                                        <Text textColor="brick" fontFamily="code"textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}}>{modelo.name}</Text>
                                        <Text fontFamily="primary" textSize="tiny">{s}</Text>
                                        <Row>
                                            <Col size={8} className="item" d="grid" style={{placeItems:"start"}}>
                                                <Text textSize={{ xs: 'caption',sm:'caption',md:'body',lg:"body"}}>Máximo de etapas:</Text>
                                            </Col>
                                            <Col size={4} className="item" d="grid" style={{placeItems:"end"}}>
                                                <Text>{modelo.nmroEtapas}</Text>
                                            </Col>
                                            <Col size={8} className="item" d="grid" style={{placeItems:"start"}}>
                                                <Text textSize={{ xs: 'caption',sm:'caption',md:'body',lg:"body"}}>Prom./Etapa:</Text>
                                            </Col>
                                            <Col size={4} className="item" d="grid" style={{placeItems:"end"}}>
                                                <Text>{modelo.costoPromedio}</Text>
                                            </Col>
                                            <Col size={8} className="item" d="grid" style={{placeItems:"start"}}>
                                                <Text textSize={{ xs: 'caption',sm:'caption',md:'body',lg:"body"}}>Precio Total:</Text>
                                            </Col>
                                            <Col size={4} className="item" d="grid" style={{placeItems:"end"}}>
                                                <Text>{modelo.costoTotal}</Text>
                                            </Col>
                                        </Row>
                                        <Col size={12} d="grid" style={{placeItems:"center"}}
                                        onClick={()=>handleClick(modelo.modeloId)}><BotonPrimario texto="Elegir" btnColor="btn-secondary my-4 w-100"/></Col>
                                    </Div>
                                </Div> 
                            </Col>
                            
                        ))}   
                    </Row>
                    </Container>
                </div>
            </Container>
            <Footer/>
        </>
    )
}

export default Compare
