import {React} from 'react'
import {Tag,Icon} from 'atomize'

const Tags = ({text,color,size,iconl,iconr}) => {
    return (
            <div class="badge badge-md badge-success-soft badge-pill text-success px-3 py-1 m-1" style={{backgroundColor:color ? color:''}}>
                <div class="tag-filter-wrapper small legend">
                    {iconl ? 
                    <Icon
                        name={iconl}
                        size="1rem"
                        color="black"
                    />
                    :<></>}
                    {text.length<33 ? text:text.substring(0, 32) + "..."}
                    
                    {iconr ? 
                    <Icon
                        name={iconr}
                        size="1rem"
                        color="black"
                    />
                    :<></>}
                </div>
            </div>   
    )
}

export default Tags
