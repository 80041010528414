import React from 'react'
import { Button, Icon, Text } from "atomize";

const BotonPrimario = ({texto,icon,font,btnColor}) => {

    var classMaker = btnColor?"btn " + btnColor:"btn btn-primary";
    return (
        <a class={classMaker}>
            <Text textSize={{xs:"paragraph",sm:"paragraph",md:"subheader",lg:"subheader"}} 
            fontFamily= {font?font:"primary"} 
            >
                {texto}
            </Text>
            {icon && <Icon
                    name={icon}
                    size={{ xs: '1rem',md:'1.2rem' }}
                    color="white"
                    m={{ l: "1rem" }}
            />}
        </a>
    )
}

export default BotonPrimario
