import {React,useState} from 'react'
import { Div, Modal, Icon, Text,Row,Col } from "atomize";
import BotonPrimario from '../Atoms/BotonPrimario'

const ProjectSelectModal = ({isOpen,onClose,inf,onSelect,selected}) => {
    const[index,setIndex]=useState(0)
    const[info,setInfo]=useState(inf)
    const s="Vivienda de interés social > aislada > 2 habitaciones > 2 baños > 2 niveles"
  return (
    
        <Modal isOpen={isOpen} onClose={onClose} align="center" maxW="50rem" rounded="md">
            <Icon
            name="Cross"
            pos="absolute"
            top="1rem"
            right="1rem"
            size="1rem"
            onClick={onClose}
            cursor="pointer"
        />
            <Row>
                <Col size={{ xs: 12,sm:12,md:12,lg:5}}>
                                    <div id="Carousel-Img" class="carousel slide" data-ride="carousel" data-interval="5000">
                                    <div class="carousel-inner">
                                        
                                        <Div className="carousel-item active">
                                            <Div bgImg={info.img[index]}
                                                bgSize="contain"
                                                bgPos="center"
                                                bgRepeat="no-repeat" h="20rem" />
                                        </Div>
                                        
                                    </div>
                                    <a class="carousel-control-prev" role="button" data-slide="prev">
                                        <Icon
                                            name="LeftArrow"
                                            size="3rem"
                                            cursor="pointer"
                                            color="brick"
                                            onClick={()=>setIndex(index===0 ? info.img.length-1:index-1)}
                                        />
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" role="button" data-slide="next">
                                        <Icon
                                            name="RightArrow"
                                            size="3rem"
                                            cursor="pointer"
                                            color="brick"
                                            onClick={()=>setIndex(index===info.img.length-1 ? 0:index+1)}
                                        />
                                        <span class="sr-only">Next</span>
                                    </a>
                                    </div>
                                </Col>
                <Col size={{ xs: 12,sm:12,md:12,lg:7}}>
                    <Text textColor="brick" textWeight="600" textSize={{ xs: 'subheader',sm:'subheader',md:'title',lg:"title"}}>{info.name}</Text>
                    <Row>
                        <Col size={10} className="item"><Text fontFamily="primary" textSize="tiny">{s}</Text></Col>
                    </Row>
                    <Text m={{b:"2rem",t:"2rem"}}textSize={{ xs: 'body',sm:'body',md:'paragraph',lg:"paragraph"}}>{info.desc}</Text>
                    <Row>
                        <Col size={6} className="item" d="grid" style={{placeItems:"start"}}>
                            <Text>Máximo de etapas:</Text>
                        </Col>
                        <Col size={6} className="item" d="grid" style={{placeItems:"end"}}>
                            <Text>{info.nmroEtapas}</Text>
                        </Col>
                        <Col size={6} className="item" d="grid" style={{placeItems:"start"}}>
                            <Text>Costo promedio por etapa:</Text>
                        </Col>
                        <Col size={6} className="item" d="grid" style={{placeItems:"end"}}>
                            <Text>{info.costoPromedio}</Text>
                        </Col>
                        <Col size={6} className="item" d="grid" style={{placeItems:"start"}}>
                            <Text>Precio total:</Text>
                        </Col>
                        <Col size={6} className="item" d="grid" style={{placeItems:"end"}}>
                            <Text>{info.costoTotal}</Text>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        <Div d="flex" justify="flex-end" m={{t:"1rem"}}>
            <Div  onClick={onClose}>
                <BotonPrimario
                    texto="Volver" btnColor="btn-secondary mr-3 py-1"
                />
            </Div>
            <Div  onClick={()=>{onSelect(info.modeloId);onClose()}}>
                <BotonPrimario
                    texto={selected ? "Eliminar":"Elegir"}
                    color="brick"
                    btnColor="btn-primary py-1"
                />
            </Div>
            
        </Div>
        
        </Modal>
    
  );
}

export default ProjectSelectModal
